import React, { useState } from "react";

const LogHistory = ({
  LogArray = [],
  logType = "comments",
  loadData = () => {},
}) => {
  return (
    <div className="px-3 flex flex-col col-span-2 pt-2 max-md:col-span-1">
      <span className="flex">
        <h1
          className={
            " font-semibold text-[18px] p-1 hover:bg-tertiary rounded-tl-lg border-t border-l border-r " +
            (logType == "comments" && " bg-tertiary ")
          }
          onClick={() => {
            loadData("comments");
          }}
        >
          Comments
        </h1>
        <h1
          className={
            " font-semibold text-[18px] p-1 hover:bg-tertiary rounded-tr-lg border-t border-r " +
            (logType == "history" && " bg-tertiary ")
          }
          onClick={() => {
            loadData("history");
          }}
        >
          History
        </h1>
      </span>
      <hr />
      <div className="min-h-[80px] max-h-[320px] overflow-x-auto">
        {LogArray.map((log, index) => (
          <>
            <HistoryCard logData={log} />
            {LogArray.length - 1 != index && <hr />}
          </>
        ))}
        {LogArray.length == 0 && (
          <div className="text-Old_Silver p-3">No Log Found</div>
        )}
      </div>
    </div>
  );
};

function HistoryCard({ logData = {} }) {
  return (
    <>
      <div className="px-1 py-3">
        <h1>
          <b>{logData.User}</b>
          {` ${logData.Action} the `}
          <b>{logData.Field}</b>
          {` on ${logData.date} at ${logData.time}`}
        </h1>
        {((logData.old_data != "" && logData.old_data != undefined) ||
          (logData.new_data != "" && logData.new_data != undefined)) && (
          <div className="flex gap-5 items-center w-fit max-w-full pt-1 pl-4">
            <span className="w-fit max-w-[100%]">{logData.old_data}</span>
            <span className="w-fit max-w-[100%]">{" -> "}</span>
            <span className="w-fit max-w-[100%]">{logData.new_data}</span>
          </div>
        )}
      </div>
    </>
  );
}
export default LogHistory;
