import {
  CheckMandatory,
  ErrorAlert,
  getList,
  GetListDropdown,
  GetRecords,
  saveRecord,
  SuccessAlert,
  UpdateRecord,
  URLPRIFIX,
  WarningAlert,
} from "../../../functions/api";
import React, { useEffect, useState } from "react";
import { resolvePath, useLocation, useParams } from "react-router-dom";
import String from "../../../Components/Fields/String";
import { useStore } from "../../../Components/StateProvider";
import FormView from "../../../Components/Elements/FormView";
import ListView from "../../../Components/Elements/ListView";
import DatePicker from "../../../Components/Fields/DatePicker";
import Journal from "../../../Components/Fields/Journal";
import { getlocal } from "../../../functions/localstorage";
import Attachement from "../../../Components/Fields/Attachement";
import FileInput from "../../../Components/Fields/FileInput";
import { PrimaryBTN, secondaryBTN } from "../../../Constancs/cssconst";
import Dropdown from "../../../Components/Fields/Dropdown";

const TTaskForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { option, id } = useParams();
  const user = getlocal("system")?.user || {};
  const tablename = "tasks";
  const setrecord = "/tasks/createtask/13";
  const getrecord = `/tasks/gettask/${id}`;
  const updaterecord = `/tasks/updatetask/${id}/13`;


  const store = useStore();
  const init = {
    project_id: "",
    task_title: "",
    description: "",
    dueDate: "",
    priority: "",
    status: "Draft",
    activeStatus: true,
    attachmenturl: [],
  };

  useEffect(() => {
    console.log("queryParams", queryParams.get("projectName") === null);

    store
      .setmodule(tablename, init)
      .then((res) => console.log("autofill check", res));
    if (id != undefined) {
      store.setheader(option.toUpperCase() + " " + tablename);
      store.setprops("activeStatus", { hidden: false });

      // props for the Mandatory field
      store.setprops(
        "task_title",
        queryParams.get("task_title")
          ? {
            mandatory: queryParams.get("task_title").includes("mandatory"),
            readonly: queryParams.get("task_title").includes("readonly"),
            hidden: queryParams.get("task_title").includes("hidden"),
          }
          : { mandatory: false }
      );
      store.setprops(
        "projectName",
        queryParams.get("projectName")
          ? {
            mandatory: queryParams.get("projectName").includes("mandatory"),
            readonly: queryParams.get("projectName").includes("readonly"),
            hidden: queryParams.get("projectName").includes("hidden"),
          }
          : { mandatory: false }
      );


      store.setprops(
        "dueDate",
        queryParams.get("dueDate")
          ? {
            mandatory: queryParams.get("dueDate")?.includes("mandatory"),
            readonly: queryParams.get("dueDate")?.includes("readonly"),
            hidden: queryParams.get("dueDate")?.includes("hidden"),
          }
          : { mandatory: false }
      );


      store.setprops(
        "priority",
        queryParams.get("priority")
          ? {
            mandatory: queryParams.get("priority")?.includes("mandatory"),
            readonly: queryParams.get("priority")?.includes("readonly"),
            hidden: queryParams.get("priority")?.includes("hidden"),
          }
          : { mandatory: false }
      );

      GetRecords(getrecord).then(async (res) => {
        console.log(tablename + " Data - ", res, res.record);
        if (res.success) {
          let records = {
            ...init,
            task_title: res.record.parent.task_title,
            projectName: res.record.parent.projectName,
            dueDate: res.record.parent.dueDate,
            priority: res.record.parent.priority,
            status: res.record.parent.status,
            activeStatus: res.record.parent.activeStatus,
            attachmenturl: res.record.parent.attachmenturl || [],
          };

          if (option === "view") {
            store.setprops("task_title", { hidden: false, readonly: true });
            store.setprops("projectName", { hidden: false, readonly: true });

            store.setprops("dueDate", { hidden: false, readonly: true });
            store.setprops("priority", { hidden: false, readonly: true });
            store.setprops("status", { hidden: false, readonly: true });
            store.setprops("activeStatus", { hidden: false, readonly: true });
            records.child = [
              {
                list: res.record.child,
                header: [
                  ["Document Title", "task_title"],
                  ["Description", "description"],
                  ["Priority", "priority"],
                  ["Due Date", "dueDate"],
                  ["Status", "status"],
                  ["Action", "ACTION"],
                ],
                table: "Documents",
              },
            ];
          } else if (option === "edit") {
            store.setprops("status", { hidden: false, readonly: false });
          }
          console.log(tablename + " Data - ", records);

          store.reset(records);
        }
      });
    } else {
      store.setheader("CREATE NEW " + tablename);
      store.setprops("activeStatus", { hidden: true });
      store.setprops("status", { hidden: true });
      // store.setvalue("status", "Draft");
      // props for the Mandatory field
      store.setprops(
        "project_id",
        queryParams.get("project_id")
          ? {
            mandatory: queryParams.get("project_id").includes("mandatory"),
            readonly: queryParams.get("project_id").includes("readonly"),
            hidden: queryParams.get("project_id").includes("hidden"),
          }
          : { mandatory: true }
      );



      store.setprops(
        "priority",
        queryParams.get("priority")
          ? {
            mandatory: queryParams.get("priority")?.includes("mandatory"),
            readonly: queryParams.get("priority")?.includes("readonly"),
            hidden: queryParams.get("priority")?.includes("hidden"),
          }
          : { mandatory: false }
      );
      store.setprops(
        "description",
        queryParams.get("description")
          ? {
            mandatory: queryParams.get("description").includes("mandatory"),
            readonly: queryParams.get("description").includes("readonly"),
            hidden: queryParams.get("description").includes("hidden"),
          }
          : { mandatory: false }
      );
    }
    GetListDropdown({
      listUrl: "/project/getprojectlist/13",
      field: "projectName",
      sort: "asc",
      count: 100,
      store: store,
    });
    GetListDropdown({
      listUrl: "/users/getusers",
      field: "userName",
      sort: "asc",
      count: 100,
      store: store,
    });
    GetListDropdown({
      listUrl: "/users/getusers",
      field: "userName",
      sort: "asc",
      count: 100,
      store: store,
    });
  }, [option, id]);

  function update() {
    store.startLoading();
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.id = Number(id);
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    GetRecords("/project/getproject/" + data.project_id / 13).then((res) => {
      console.log("getpro ", res);

      if (res.success) {
        let parentDate = new Date(res.record.parent[0].dueDate || "");
        let childDate = new Date(data.dueDate || "");
        if (parentDate >= childDate) {
          UpdateRecord(updaterecord, data).then((res) => {
            if (res.success) {
              store.stopLoading();
              SuccessAlert("Task Updated Successfully");
              store.navback();
              store.reset(init);
            }
          });
        } else {
          store.stopLoading();
          WarningAlert(
            "Document Date Exist Project Date\nProject end Date - " +
            res.record.parent[0].dueDate.split("T")[0] +
            "\nDocument DueDate - " +
            data.dueDate.split("T")[0]
          );
        }
      } else {
        store.stopLoading();
        WarningAlert("UnKnown Error Found. Please contact Admin");
      }
    });
  }

  function Submit() {
    store.startLoading();
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.createdBy = store.getuser().id;
    console.log({ setrecord, data });
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    if (CheckMandatory(tablename, store)) {
      GetRecords(`/project/getproject/${data.project_id}/13`).then((res) => {
        console.log("Pro",res);
        
        if (res.success) {
          let parentStartDate = new Date(res.record.parent.startDate || "");
          let parentEndDate = new Date(res.record.parent.endDate || "");
          let childDate = new Date(data.dueDate || "");
          if (parentStartDate >= childDate && parentEndDate >= childDate) {
            saveRecord(setrecord, data).then((res) => {
              console.log("Responce of saveRecord - ", res);
              if (res.success) {
                store.stopLoading();
                SuccessAlert("Task Submitted Successfully");
                store.navTo("/" + URLPRIFIX + "/litigation/tasks/view/" + res.Id.id);
                store.reset(init);
              }
            });
          } else {
            store.stopLoading();
            ErrorAlert(
              "Task due date cannot be later than the document due date!"
            );
          }
        }
      });
    }
  }

  return (
    <FormView
      actions={[
        {
          Name: "Back",
          onclick: () => {
            // store.navTo("/" + URLPRIFIX + "/projects/list");
            store.navback();
            store.reset(init);
          },
          visible: true,
        },
        {
          Name: "Submit",
          onclick: Submit,
          type: "primary",
          visible: id === undefined,
        },
        {
          Name: "Update",
          onclick: update,
          type: "primary",
          visible: option === "edit" && store.getprops("update"),
        },
        {
          Name: "Create Task",
          type: "primary",
          onclick: () => {
            let data = store.getrecord();
            console.log("autofill check On Create task - ", data, id);

            store.setrecord("document", {
              project_id: id,
              assigned_to: "",
              task_title: "",
              dueDate: "",
              priority: "",
              editor_id: "",
              status: "Draft",
              description: "",
              attachmenturl: [],
              activeStatus: true,
              child: [],
            });
            store.navTo(
              "/demo/documents/create?project_id=readonly&film_id=readonly&contact_id=readonly"
            );
          },
          visible: option === "view" && getlocal("properties")?.document?.add,
        },
      ]}
    >

      <div className="grid grid-cols-2 max-md:grid-cols-1 gap-6">
        <String name="task_title" label="Task Name :" />
        <Dropdown
          name="project_id"
          label="Project Name :"
          list={store.getprops("projectName_list") || []}

        />

        {/* <Dropdown */}
        <Dropdown
          name="assigned_to"
          label="Assigned To :"
          list={store.getprops("userName_list") || []}
        />

        <Dropdown
          name="priority"
          label="Priority :"
          list={[
            { value: "High", label: "High" },
            { value: "Medium", label: "Medium" },
            { value: "Low", label: "Low" },
          ]}
        />
        <Journal
          name="description"
          label="Description :"
          doubleline={true}
        />
        <Dropdown
          name="status"
          label="Status :"
          list={[
            { value: "Draft", label: "Draft" },
            { value: "inprogress", label: "InProgress" },
            { value: "Completed", label: "Completed" },
            { value: "Billed", label: "Billed" },
          ]}
        />
        <Dropdown
          name="activeStatus"
          label="Active Status :"
          list={[
            { value: true, label: "Active" },
            { value: false, label: "InActive" },
          ]}
        />


        <DatePicker name="dueDate" label="Due Date :" />


        <div className="col-span-2 max-md:col-span-1 px-2">
          <div className="font-bold text-[20px]">Attachements</div>
          <div className="grid grid-cols-5">
            {store.getvalue("attachmenturl")?.length > 0 ? (
              store.getvalue("attachmenturl").map((item, index) => {
                // console.log("test 223", item);
                return (
                  <div className="col-span-1 flex border-r pr-6 w-full  items-center">
                    <Attachement name={"attachmenturl"} fileurl={item} />
                    <button
                      className="hover:bg-tertiary p-1 h-fit w-fit rounded-full"
                      onClick={() => {
                        let arr = [];
                        store
                          .getvalue("attachmenturl")
                          .map((attachitem, attachindex) => {
                            if (attachindex !== index) {
                              arr.push(attachitem);
                            }
                          });
                        store.setvalue("attachmenturl", arr);
                      }}
                    >
                      X
                    </button>
                  </div>
                );
              })
            ) : (
              <>No Data</>
            )}
            {option !== "view" && (
              <FileInput
                prefix={"TASK" + id}
                onchange={(file) => {
                  let attachmenturl = store.getvalue("attachmenturl") || [];
                  attachmenturl.push(file);
                  store.setvalue("attachmenturl", attachmenturl);
                }}
              />
            )}
          </div>
        </div>

        <div className="col-span-2 max-md:col-span-1 px-2">
          {store.getvalue("child") &&
            store.getvalue("child").map((child) => (
              <>
                {" "}
                <div className="font-bold text-[20px]">
                  {child.table.toUpperCase()}
                </div>
                <ListView
                  Tablehead={
                    "min-w-[150px] pr-[10px] max-sm:text-[26px] md:text-[20px] lg:text-[16px] xl:text-[20px] font-bold"
                  }
                  Tablerecord="pr-[10px]  max-sm:text-[26px] md:text-[20px] lg:text-[12px] xl:text-[16px] cursor-pointer "
                  onview={(index) => {
                    store.navTo("/demo/documents/view/" + index);
                  }}
                  height={"fit"}
                  header={child.header}
                  records={child.list}
                />
              </>
            ))}
        </div>
      </div>
    </FormView>
  );
};

export default TTaskForm;
