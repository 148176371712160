import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import {
  baseurl,
  ErrorAlert,
  GetDateTimeFormat,
  GetRecords,
  RupeeFormat,
  UploadFile,
  URLPRIFIX,
  WarningAlert,
} from "../../../functions/api";
import AccountsLayout from "../AccountsLayout";
import FormView from "../../../Components/Elements/FormView";
import { MdOutlineFileUpload } from "react-icons/md";
import { FaRegEye } from "react-icons/fa6";
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";
import { URL_Account_TDSReport } from "../../../Constancs/apiconst";

const NestedListView = ({ records, store }) => {
  const { companyId } = useParams();

  const [expandedIds, setExpandedIds] = useState([]);

  const [transactionDetails, setTransactionDetails] = useState({});

  const toggleExpand = (accountName) => {
    setExpandedIds((prev) =>
      prev.includes(accountName)
        ? prev.filter((item) => item !== accountName)
        : [...prev, accountName]
    );
  };

  const renderTransactions = (transactions) => {
    return transactions.map((transaction, index) => (
      <div
        key={index}
        className="flex justify-between w-full cursor-pointer text-[#344cb7] hover:underline"
        onClick={() => {
          switch (transaction.transactiontype_id) {
            case 1:
              store.navTo(
                `/${URLPRIFIX}/${companyId}/accounts/invoice/view/${transaction.transaction_id}`
              );
              break;
            case 2:
              store.navTo(
                `/${URLPRIFIX}/${companyId}/accounts/journal/view/${transaction.transaction_id}`
              );
              break;
            case 3:
              store.navTo(
                `/${URLPRIFIX}/${companyId}/accounts/expense/view/${transaction.transaction_id}`
              );
              break;
            case 4:
              store.navTo(
                `/${URLPRIFIX}/${companyId}/accounts/receipt/view/${transaction.transaction_id}`
              );
              break;
            case 7:
              store.navTo(
                `/${URLPRIFIX}/${companyId}/accounts/payable/view/${transaction.transaction_id}`
              );
              break;

            default:
              break;
          }
        }}
      >
        <span className="font-medium">{transaction.referenceno}</span>
        <span className="font-medium">{RupeeFormat(transaction.amount)}</span>
      </div>
    ));
  };

  const renderRecords = (items, level = 0) => {
    return items.map((item) => (
      <div key={item.accountName} className={`pl-${level * 4}`}>
        <div
          className="flex items-center cursor-pointer"
          onClick={() => toggleExpand(item.accountName)}
        >
          <span className="flex justify-between w-full hover:bg-Azureish_White">
            <span className="font-medium">
              {(item.subCategories?.length > 0 ||
                item.transactionDetails?.length > 0) && (
                <span className="mr-1 text-[12px]">
                  {item?.subCategories?.length > 0 &&
                    (!expandedIds.includes(item.accountName) ? "▼" : "►")}
                  {item?.transactionDetails?.length > 0 &&
                    (!expandedIds.includes(item.accountName) ? "▼" : "►")}
                  {/* ◄ ▲ ▼ ►  */}
                </span>
              )}
              {item.accountName}
            </span>
            <span className="font-medium">{RupeeFormat(item.amount)}</span>
          </span>
        </div>
        {!expandedIds.includes(item.accountName) &&
        item?.subCategories?.length > 0 ? (
          <div className="ml-8 ">
            {renderRecords(item.subCategories, level + 1)}
          </div>
        ) : (
          !expandedIds.includes(item.accountName) &&
          item?.transactionDetails?.length > 0 && (
            <div className="ml-8 ">
              {renderTransactions(item.transactionDetails, level + 1)}
            </div>
          )
        )}
      </div>
    ));
  };

  return <div>{renderRecords(records)}</div>;
};

const TDSReport = () => {
  const { companyId } = useParams();

  const navigate = useNavigate();
  // const getrecord = `/users/users/${id}`;
  const attachment_Prefix = "Journal_";
  const store = useStore();

  // *initial values
  const init = {
    TDS_payable: [],
    TDS_Receivable: [],
    equity: [],
    TotalPayable: [],
    TotalReceivable: [],
    TotalEquity: [],
    fromDate: "",
    ToDate: "",
  };

  const initProps = {
    add: false,
    delete: false,
    on_off: false,
    print: false,
    update: false,
    view: false,
    TDS_payable: {},
    TDS_Receivable: {},
    Equity: {},
    equity: {},
    fromDate: {},
    ToDate: {},
  };

  // *states declaration for records and props
  const [Record, setRecord] = useState(init);
  const [props, setprops] = useState(initProps);

  // *initial values set on the page load
  useEffect(() => {
    const dateTime = GetDateTimeFormat();
    store.setmodule("tdsReport", init);

    handleMultiFieldChange({
      fromDate: dateTime.MonthStartDate + "Z",
      ToDate: dateTime.MonthEndDate + "Z",
    });

    loadRecord();
  }, []);

  function loadRecord(fromDate, ToDate) {
    console.log("accounting/TDSReport");
    GetRecords(
      `${URL_Account_TDSReport}${companyId}?startDate=${
        fromDate || Record.fromDate
      }&endDate=${ToDate || Record.ToDate}`
    ).then((res) => {
      console.log("accounting/TDSReport", res);
      if (res.success) {
        res = res?.record;
        handleMultiFieldChange({
          TDS_payable: res.tdsPayableEntries,
          TDS_Receivable: res.tdsReceivableEntries,
          TotalPayable: res.totalCredit,
          TotalReceivable: res.totalDebit,
        });
      }
    });
  }

  // *print the Record and Props if any value change between the Record and Props
  useEffect(() => {
    console.log("Record State change -> ", Record);
    console.log("Props State change -> ", props);
  }, [Record, props]);

  // *create Or update the Record
  function update() {}
  function Submit() {}

  // *Check for the mandatory fields if empty or not
  function CheckMandatory() {
    let out = true;
    let Fields = "";
    for (let obj in props) {
      if (typeof props[obj] === "object") {
        if (props[obj].mandatory) {
          if (Record[obj] == "" || Record[obj] == " ") {
            HandlePropsChange(obj, { mandatory: true, show: true });
            out = false;
          } else {
            HandlePropsChange(obj, { mandatory: true, show: false });
          }
        }
      }
    }
    out === false && ErrorAlert("Please fill the Mandatory Fields.");
    out === false && store.stopLoading();
    return out;
  }

  // *Handle Fields changes in Record States
  function handleFieldChange(fieldName, value) {
    setRecord((prev) => {
      // console.log("fields", ...prev);
      return { ...prev, [fieldName]: value };
    });
  }
  function handleMultiFieldChange(value = {}) {
    setRecord((prev) => {
      for (let fieldName in value) {
        prev[fieldName] = value[fieldName];
      }
      return { ...prev };
    });
  }

  // *Handle Props Changes in Props States
  function HandlePropsChange(fieldName, value = {}) {
    setprops((prev) => {
      let oldprop = prev[fieldName];

      for (let prophead in value) {
        oldprop[prophead] = value[prophead];
      }
      prev[fieldName] = oldprop;
      return { ...prev };
    });
  }
  function HandleMultiPropsChange(NewProps = {}) {
    setprops((prev) => {
      for (let fieldName in NewProps) {
        let oldprop = prev[fieldName];
        let newprop = NewProps[fieldName];
        console.log("typeof newprop", typeof newprop);

        if (typeof newprop === "object") {
          for (let prophead in newprop) {
            oldprop[prophead] = newprop[prophead];
          }
          prev[fieldName] = oldprop;
        } else {
          prev[fieldName] = newprop;
        }
      }
      console.log("updated props", prev);

      return { ...prev };
    });
  }

  // *Handle Change in the fields
  function HandleAttachement(event) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", event.target.files[0]);
    UploadFile(attachment_Prefix, bodyFormData).then((res) => {
      if (res.success) {
        handleFieldChange("attachment", res.data);
      }
    });
  }
  const ExportExcel = () => {
    if (
      !Record ||
      (Record.TDS_Receivable.length === 0 && Record.TDS_payable.length === 0)
    ) {
      WarningAlert("No data available to export.");
      return;
    } else {
      let rows = [];
      const fromDate = new Date(Record.fromDate);
      const toDate = new Date(Record.ToDate);
      const formattedFromDate = fromDate.toISOString().split("T")[0];
      const formattedToDate = toDate.toISOString().split("T")[0];
      rows.push(["", "", ` ${formattedFromDate} - ${formattedToDate} `]);
      rows.push(["", "", "Balance"]);
      rows.push(["TDS-PAYABLES", "", ""]);
      Record.TDS_payable.forEach((tds) => {
        rows.push([tds.accountName, "", tds.amount, "", ""]);
        tds.transactionDetails.forEach((subCategory) => {
          rows.push([
            "",
            `  ${subCategory.referenceno}`,
            subCategory.amount,
            "",
            "",
          ]);
        });
        rows.push([""]);
      });
      rows.push(["TDS-RECEIVABLES", "", ""]);
      Record.TDS_Receivable.forEach((tds1) => {
        rows.push([tds1.accountName, "", tds1.amount, "", ""]);
        tds1.transactionDetails.forEach((subCategory) => {
          rows.push([
            "",
            `  ${subCategory.referenceno}`,
            subCategory.amount,
            "",
            "",
          ]);
        });
        rows.push([""]);
      });
      rows.push(["TOTAL PAYABLE", "", Record.TotalPayable]);
      rows.push(["TOTAL RECEIVABLE", "", Record.TotalReceivable]);
      const ws = XLSX.utils.aoa_to_sheet(rows);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Balance Sheet");
      XLSX.writeFile(wb, "Tds_Report.xlsx");
    }
  };

  const ExportPDF = () => {
    if (
      !Record ||
      (Record.TDS_Receivable.length === 0 && Record.TDS_payable.length === 0)
    ) {
      WarningAlert("No data available to export.");
      return;
    } else {
      const doc = new jsPDF();
      const data = Record;
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      const HeaderTitle = "TDS Report";
      const logo = "/Assets/images/Logo.png";
      const textWidth = doc.getTextWidth(HeaderTitle);
      const xPosition = (pageWidth - textWidth) / 2;
      const fromDate = new Date(Record.fromDate);
      const toDate = new Date(Record.ToDate);
      const formattedFromDate = fromDate.toISOString().split("T")[0];
      const formattedToDate = toDate.toISOString().split("T")[0];

      doc.addImage(logo, "PNG", 15, 5, 15, 15);
      const dateRange = `  ${formattedFromDate} - ${formattedToDate}`;
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 66);
      doc.text("ABSOLUTE LEGAL", 8, 25);
      doc.text("LAW FIRM", 15, 30);

      doc.setTextColor(0, 0, 0);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text(HeaderTitle, xPosition, 20);

      doc.setFontSize(12);
      doc.setDrawColor(169, 169, 169);
      doc.setLineWidth(0.2);
      const textWidth1 = doc.getTextWidth(dateRange);
      const textHeight = 8;
      doc.rect(155, 5, textWidth1 + 2, textHeight);
      doc.text(dateRange, 155, 10);

      doc.setFont("helvetica", "bold");

      let yPosition = 40;
      const addContentToPage = (text, x, y) => {
        if (y > doc.internal.pageSize.height - 20) {
          doc.addPage();
          y = 10;
        }
        doc.text(text, x, y);
        return y;
      };
      yPosition = addContentToPage("PAYABLES", 20, yPosition);
      yPosition = addContentToPage("Balance", 120, yPosition);
      doc.setLineWidth(0.5);
      doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
      yPosition += 10;

      doc.setFont("helvetica", "normal");
      let totalPayable = 0;

      data.TDS_payable.forEach((tds) => {
        yPosition = addContentToPage(tds.accountName, 20, yPosition);
        yPosition = addContentToPage(`${tds.amount}`, 120, yPosition);
        totalPayable += tds.amount;
        doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
        yPosition += 10;
        tds.transactionDetails.forEach((sub) => {
          yPosition = addContentToPage(`   ${sub.referenceno}`, 30, yPosition);
          yPosition = addContentToPage(`${sub.amount}`, 120, yPosition);
          doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
          yPosition += 8;
        });
      });
      yPosition = addContentToPage("Total Payables:", 20, yPosition);
      yPosition = addContentToPage(`${Record.TotalPayable}`, 120, yPosition);
      yPosition += 10;

      doc.setFont("helvetica", "bold");
      yPosition = addContentToPage("RECEIVABLES", 20, yPosition);
      yPosition = addContentToPage("Balance", 120, yPosition);
      doc.setLineWidth(0.5);
      doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
      yPosition += 10;

      doc.setFont("helvetica", "normal");
      let totalReceivable = 0;

      data.TDS_Receivable.forEach((rec) => {
        yPosition = addContentToPage(rec.accountName, 20, yPosition);
        yPosition = addContentToPage(`${rec.amount}`, 120, yPosition);
        totalReceivable += rec.amount;
        doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
        yPosition += 10;
        rec.transactionDetails.forEach((sub) => {
          yPosition = addContentToPage(`   ${sub.referenceno}`, 30, yPosition);
          yPosition = addContentToPage(`${sub.amount}`, 120, yPosition);
          doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
          yPosition += 8;
        });
      });
      yPosition = addContentToPage("Total Receivables:", 20, yPosition);
      yPosition = addContentToPage(`${Record.TotalReceivable}`, 120, yPosition);
      yPosition += 10;

      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        const pageNumber = `Page ${i}`;
        const pageNumberX = (pageWidth - doc.getTextWidth(pageNumber)) / 2; // Center the page number
        doc.setFontSize(10);
        doc.text(pageNumber, pageNumberX, pageHeight - 10); // Place page number at bottom center
      }

      doc.save("Tds_Report.pdf");
    }
  };

  return (
    <AccountsLayout HeaderTitle={"TDS Report"}>
      <div className="flex px-10 gap-3 py-1 bg-secondary justify-center items-center border-b border-Old_Silver">
        <div // fromDate
          className={
            "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.fromDate.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[100px] max-w-full"}>
            From Date
            {props.fromDate.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border-b border-Old_Silver border-opacity-35 font-medium disabled:bg-[#c6c6ca] text-Old_Silver disabled:bg-opacity-20 px-2 py-1 " +
              (props.fromDate.mandatory && props.fromDate.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"fromDate"}
            type="date"
            value={Record.fromDate.split("T")[0]}
            disabled={props.fromDate.readonly}
            onChange={(event) => {
              console.log("fromDate new Tsate");
              if (
                new Date(event.target.value + "T00:00:00Z") <
                new Date(Record.ToDate)
              ) {
                handleFieldChange(
                  "fromDate",
                  event.target.value + "T00:00:00Z"
                );
                loadRecord(event.target.value + "T00:00:00Z", Record.ToDate);
              } else {
                handleMultiFieldChange({
                  fromDate: event.target.value + "T00:00:00Z",
                  ToDate: event.target.value + "T23:59:59Z",
                });
                loadRecord(
                  event.target.value + "T00:00:00Z",
                  event.target.value + "T23:59:59Z"
                );
              }
            }}
          />
        </div>
        <div // ToDate
          className={
            "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
            (props.ToDate.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[100px] max-w-full"}>
            To Date
            {props.ToDate.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-full border-b border-Old_Silver border-opacity-35 font-medium disabled:bg-[#c6c6ca] text-Old_Silver disabled:bg-opacity-20 px-2 py-1 " +
              (props.ToDate.mandatory && props.ToDate.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"ToDate"}
            type="date"
            value={Record.ToDate.split("T")[0]}
            disabled={props.ToDate.readonly}
            onChange={(event) => {
              if (
                new Date(Record.fromDate) <
                new Date(event.target.value + "T23:59:59Z")
              ) {
                handleFieldChange("ToDate", event.target.value + "T23:59:59Z");
                loadRecord(Record.fromDate, event.target.value + "T23:59:59Z");
              } else {
                WarningAlert(
                  "The 'To Date' cannot be earlier than the 'From Date'"
                );
              }
            }}
          />
        </div>
        <div className="flex gap-5">
          <button
            className={
              "py-2 px-6 rounded-xl font-bold text-[12px]  bg-primary text-secondary"
            }
            onClick={ExportExcel}
          >
            EXCEL
          </button>
          <button
            className={
              "py-2 px-6 rounded-xl font-bold text-[12px]  bg-primary text-secondary"
            }
            onClick={ExportPDF}
          >
            PDF
          </button>
        </div>
      </div>
      <div className="flex h-[90%] px-10 pt-2 bg-secondary rounded-lg">
        <div className="w-[50%] p-2 ">
          <div className="h-[95%]">
            <h1 className="font-bold text-[18px]">TDS - Payables</h1>
            <div className=" h-[90%] overflow-y-auto p-1">
              <NestedListView records={Record.TDS_payable} store={store} />
            </div>
          </div>
          <div className="h-[5%] font-semibold ">
            {"Total Payable : " + Record.TotalPayable}
          </div>
        </div>
        <div className="w-[50%] p-2 ">
          <div className="h-[95%]">
            <h1 className="font-bold text-[18px]">TDS - Receivables</h1>
            <div className=" h-[90%] overflow-y-auto p-1">
              <NestedListView records={Record.TDS_Receivable} store={store} />
            </div>
          </div>
          <div className="h-[5%] font-semibold ">
            {"Total Receivable : " + Record.TotalReceivable}
          </div>
        </div>
      </div>
    </AccountsLayout>
  );
};

export default TDSReport;
