import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import { useEffect, useState } from "react";
import {
  getlocal,
  getStorage,
  setStorage,
} from "../../../functions/localstorage";
import {
  baseurl,
  DeleteRecord,
  ErrorAlert,
  getList,
  GetListDropdown,
  GetRecords,
  saveRecord,
  SuccessAlert,
  UploadFile,
  WarningAlert,
} from "../../../functions/api";
import AccountsLayout from "../AccountsLayout";
import FormView from "../../../Components/Elements/FormView";
import { MdOutlineFileUpload } from "react-icons/md";
import { FaRegEye } from "react-icons/fa6";
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";
import { PrimaryBTN } from "../../../Constancs/cssconst";
import { FaArrowRight, FaPenNib, FaTruckLoading } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import axios from "axios";
import {
  URL_Account_Charts_List,
  URL_Account_Customer_List,
  URL_Account_Invoice_for_status_completed,
  URL_Account_Invoice_List,
  URL_Account_Receipt_By_ID,
  URL_Account_Receipt_Create,
} from "../../../Constancs/apiconst";
import { IoIosCloseCircleOutline } from "react-icons/io";

function FormHeader({
  State = "New",
  FormName = "",
  FormNumber = "",
  Actions = [],
}) {
  return (
    <div className="flex items-center justify-between bg-secondary h-[38px] rounded-lg p-1 shadow-[0px_0px_10px_3px_rgba(0,0,0,0.1)] ">
      <div className="flex gap-3 h-full items-center">
        <span className=" text-[14px] border flex items-center px-1 py-0.5 rounded-lg">
          {State}
        </span>
        <span className="text-[14px]  flex flex-col">
          <span className="text-[12px] font-bold text-Ocean_Blue">
            {FormName}
          </span>
          <span className="text-[14px] font-semibold ">{FormNumber}</span>
        </span>
      </div>
      <div className="flex gap-3 h-full items-center mr-8">
        {Actions.map((item, index) => {
          if (item.visible)
            return (
              <button
                key={index}
                className={
                  "border px-2 py-1 rounded-lg font-semibold hover:bg-tertiary " +
                  (item.type === "primary"
                    ? " bg-Ocean_Blue text-secondary "
                    : " bg-Alice_Blue ")
                }
                onClick={item.onClick}
              >
                {item.ButtonName}
              </button>
            );
        })}
      </div>
    </div>
  );
}

function FormStage({ Stages = [] }) {
  return (
    <div className="flex items-center justify-center h-[38px] gap-3">
      {Stages.map((stage, index) => (
        <div key={index} className=" flex gap-1 items-center">
          <span
            className={
              " border p-1 rounded-full text-[14px] " +
              (stage.selected
                ? " bg-Ocean_Blue text-secondary "
                : stage.completed
                ? " bg-[#3ec95c] text-secondary "
                : " ")
            }
          >
            {stage.completed ? <TiTick /> : stage.icon}
          </span>
          <h3 className="text-[14px] pr-3">{stage.Name}</h3>
          {index < Stages.length - 1 ? <FaArrowRight /> : ""}
        </div>
      ))}
    </div>
  );
}

const ReceiptForm = () => {
  const { companyId, option, receiptId } = useParams();
  const navigate = useNavigate();
  const getrecord = `/users/users/${receiptId}`;
  const attachment_Prefix = "Journal_";
  const store = useStore();
  let storage = getStorage();

  // *initial values
  const init = {
    receipt_no: "REP/24-25/0002",
    customer_id: "",
    deposite_to: "",
    amount: "",
    recepit_date: "",
    items: [{ amount: "", invoice_due_amount: 0, invoice_no: 0 }],

    payment_total: 0,
    notes: "Notes: ",
    terms_and_conditions: "Terms & Conditions: ",
    attachment_url: [],
  };
  const initProps = {
    add: false,
    delete: false,
    on_off: false,
    print: false,
    update: false,
    view: false,

    receipt_no: {},
    amount: {},
    deposite_to: {
      deposite_to_list: [{ label: "Please Choose", value: "" }],
    },
    customer_id: {
      customer_id_list: [{ label: "Please Choose", value: "" }],
    },
    recepit_date: {},
    items: {
      Invoice_list: [],
      MandatoryItems: {},
    },

    payment_total: {},
    notes: {},
    terms_and_conditions: {},
    attachment_url: {},
  };

  // *states declaration for records and props
  const [Record, setRecord] = useState(init);
  const [props, setprops] = useState(initProps);

  // *initial values set on the page load
  useEffect(() => {
    store.setmodule("receipts", init);
    store.stopLoading();
    const localprops = getlocal("properties")?.receipts || {};
    HandleMultiPropsChange(localprops);
    store.setheader("CREATE NEW Company");
    let Invoice_list = props?.items?.Invoice_list;
    if (receiptId !== undefined) {
      GetRecords(URL_Account_Receipt_By_ID + receiptId).then((res) => {
        if (res.success) {
          res = res.record;
          console.log("URL_Account_Receipt_By_ID", res);
          handleMultiFieldChange({
            receipt_no: res.expense.receipt_no,
            customer_id: res.expense.customer_id,
            deposite_to: res.expense.deposite_to,
            amount: res.expense.amount,
            recepit_date: res.expense.recepit_date,
            items: res.items.map((item) => {
              Invoice_list.push({
                invoice_no: item.invoice_no,
                id: item.invoice_id,
              });
              return {
                id: item.id,
                invoice_id: item.invoice_id,
                invoice_due_amount: item.due_amount,
                amount: item.amount,
              };
            }),
            payment_total: res.expense.payment_total,
            notes: "Notes: " + res.expense.notes,
            terms_and_conditions:
              "Terms & Conditions: " + res.expense.terms_and_conditions,
            attachment_url: res.expense.attachment_url || [],
          });
          HandlePropsChange("items", {
            ...props.items,
            Invoice_list: Invoice_list,
          });
        }
      });
      if (option === "view") {
        HandleMultiPropsChange({
          customer_id: { readonly: true },
          deposite_to: { readonly: true },
          recepit_date: { readonly: true },
          amount: { readonly: true },
          items: { readonly: true },
          notes: { readonly: true },
          terms_and_conditions: { readonly: true },
          payment_total: { readonly: true },
        });
      }
    } else {
      GetRecords(
        "/api/v2/paymentreceipt/generate-receipt-id/" + companyId
      ).then((res) => {
        if (res.success) {
          res = res.record;
          handleFieldChange("receipt_no", res);
          console.log("receipt_no", res);
        }
      });
      HandleMultiPropsChange({
        customer_id: { mandatory: true },
        deposite_to: { mandatory: true },
        recepit_date: { mandatory: true },
        amount: { mandatory: true },
      });
    }
    getList({
      listUrl: URL_Account_Customer_List + "/" + companyId,
      count: 1000,
    }).then((res) => {
      if (res.success) {
        res = res.data.list || [];
      }
      let customer_id_list = [...props.customer_id.customer_id_list];
      res?.map((item) => {
        customer_id_list.push({
          ...item,
          value: item.id,
          label: item.clientname,
        });
      });

      HandlePropsChange("customer_id", {
        ...props.customer_id,
        customer_id_list,
      });
    });

    GetPaidThoughtList();
  }, [option, receiptId]);

  function GetInvoiceList(customer_id) {
    GetRecords(
      URL_Account_Invoice_for_status_completed + `${companyId}/${customer_id}`
    ).then((res) => {
      console.log("URL_Account_Invoice_List", res);
      if (res.success) {
        res = res?.record || {};
        console.log("URL_Account_Invoice_List", res);
        HandlePropsChange("items", { ...props.items, Invoice_list: [...res] });
      }
    });
  }

  // *print the Record and Props if any value change between the Record and Props
  useEffect(() => {
    console.log("Record State change -> ", Record);
    console.log("Props State change -> ", props);
  }, [Record]);

  // *create Or update the Record
  function update() {}
  function Submit() {
    store.startLoading();
    let savedata = { ...Record };
    savedata.modified_by = store.getuser().id;
    savedata.created_by = store.getuser().id;
    savedata.terms_and_conditions = savedata.terms_and_conditions.replace(
      "Terms & Conditions:",
      ""
    );
    savedata.notes = savedata.notes.replace("Notes:", "");
    savedata.company_id = companyId;
    console.log("Submiting savedata", savedata);
    if (Number(savedata.amount) == Number(savedata.payment_total)) {
      if (CheckMandatory() && CheckForReceiptsRecords()) {
        saveRecord(URL_Account_Receipt_Create, savedata).then((res) => {
          console.log("Resonce of saveRecord - ", res);
          if (res.success) {
            store.stopLoading();
            SuccessAlert("Receipt submitted Successfully");
            store.navback();
          } else {
            store.stopLoading();
            ErrorAlert(
              res?.errormessage?.response?.data?.errorMessage ||
                " Error Found Please contact the Admin"
            );
          }
        });
      }
    } else {
      store.stopLoading();

      WarningAlert("Amount Received and total amount should be equal!");
    }
  }

  // *Check for the mandatory fields if empty or not
  function CheckMandatory() {
    let out = true;
    let Fields = "";
    for (let obj in props) {
      if (typeof props[obj] === "object") {
        if (props[obj].mandatory) {
          if (Record[obj] == "" || Record[obj] == " ") {
            HandlePropsChange(obj, { mandatory: true, show: true });
            out = false;
          } else {
            HandlePropsChange(obj, { mandatory: true, show: false });
          }
        }
      }
    }
    out === false && ErrorAlert("Please fill the Mandatory Fields.");
    out === false && store.stopLoading();
    return out;
  }
  // *Check for the Receipt records fields if empty or not
  function CheckForReceiptsRecords() {
    let Receipts = [...Record.items];
    let MandatoryItems = { ...props.items.MandatoryItems };
    let out = undefined;

    Receipts.map((receipt, index) => {
      console.log(
        "CheckForReceiptsRecords",
        receipt.invoice_due_amount != 0 &&
          receipt.invoice_no != 0 &&
          receipt.amount != "" &&
          receipt.amount != 0,
        receipt.invoice_due_amount != 0,
        receipt.invoice_no != 0,
        receipt.amount != "",
        receipt.amount != 0
      );
      if (
        receipt.charts_acc_id != 0 &&
        receipt.amount != "" &&
        receipt.amount != 0
      ) {
        out = out == false ? false : true;
        MandatoryItems = { ...MandatoryItems, [index]: false };
        console.log("CheckForReceiptsRecords");
      } else {
        out = false;
        MandatoryItems = { ...MandatoryItems, [index]: true };
        console.log("CheckForReceiptsRecords");
      }
      HandlePropsChange("items", {
        ...props.items,
        MandatoryItems: MandatoryItems,
      });
      console.log("CheckForReceiptsRecords:MandatoryItems", {
        ...props.items.MandatoryItems,
      });
    });
    store.stopLoading();
    if (out == undefined || out == false)
      ErrorAlert("Please Fill the Invoice Details");
    return out;
  }

  // *Handle Fields changes in Record States
  function handleFieldChange(fieldName, value) {
    setRecord((prev) => {
      return { ...prev, [fieldName]: value };
    });
  }
  function handleMultiFieldChange(value = {}) {
    setRecord((prev) => {
      for (let fieldName in value) {
        prev[fieldName] = value[fieldName];
      }
      return { ...prev };
    });
  }

  // *Handle Props Changes in Props States
  function HandlePropsChange(fieldName, value = {}) {
    setprops((prev) => {
      let oldprop = prev[fieldName];

      for (let prophead in value) {
        oldprop[prophead] = value[prophead];
      }
      prev[fieldName] = oldprop;
      return { ...prev };
    });
  }
  function HandleMultiPropsChange(NewProps = {}) {
    setprops((prev) => {
      for (let fieldName in NewProps) {
        let oldprop = prev[fieldName];
        let newprop = NewProps[fieldName];
        console.log("typeof newprop", typeof newprop);

        if (typeof newprop === "object") {
          for (let prophead in newprop) {
            oldprop[prophead] = newprop[prophead];
          }
          prev[fieldName] = oldprop;
        } else {
          prev[fieldName] = newprop;
        }
      }
      console.log("updated props", prev);

      return { ...prev };
    });
  }

  // *Handle Change in the fields
  function HandleCustomer(value) {
    let customer_id_list = GetRecordFromList(
      [...props.customer_id.customer_id_list],
      value
    );

    console.log("customer_id_list", customer_id_list, value);
    const customer = GetRecords(
      `/api/v2/Customer/getclient/` + value + "/" + companyId
    ).then((res) => {
      console.log("HandleCustomer customer", res.record.receivable_ledger_id);

      if (value == "") {
        handleFieldChange("customer_id", value);
      } else if (
        res.record.receivable_ledger_id != undefined &&
        res.record.receivable_ledger_id != 0 &&
        res.record.receivable_ledger_id != null
      ) {
        let items = Record.items;
        items[0].charts_acc_id = res.record.receivable_ledger_id || 0;
        handleFieldChange("items", items);
        handleFieldChange("customer_id", value);
      } else {
        if (
          res.record.receivable_ledger_id === 0 ||
          res.record.receivable_ledger_id === undefined ||
          res.record.receivable_ledger_id != null
        ) {
          ErrorAlert(
            "The customer is not linked with the Chart of Account. Please add the Receivable Account to the Customer"
          );
        }
      }
    });

    // if (value == "") {
    //   handleFieldChange("customer_id", value);
    // } else if (
    //   customer_id_list.receivable_ledger_id != 0 &&
    //   customer_id_list.receivable_ledger_id != undefined
    // ) {
    //   handleFieldChange("customer_id", value);
    // } else {
    //   if (
    //     customer_id_list.receivable_ledger_id === 0 ||
    //     customer_id_list.receivable_ledger_id === undefined
    //   ) {
    //     ErrorAlert(
    //       "The customer is not linked with the Chart of Account. Please add the Receivable Account to the Customer"
    //     );
    //   }
    // }

    // load invoice list
    GetInvoiceList(value);

    TotalCalculation();
  }
  function TotalCalculation(items = Record.items) {
    let amountUsed = 0;
    let Total = 0;

    items.map((item) => {
      if (item.amount !== "" && item.amount !== undefined) {
        amountUsed += Number(item.amount);
      }
    });

    Total = amountUsed;
    // handleFieldChange("amountUsed", amountUsed.toFixed(2));
    handleFieldChange("payment_total", Total.toFixed(2));
  }
  function HandleDiscountValue(discountPercentage) {
    let discountValue = 0;
    let taxing = Number(Record.taxing);
    let subTotal = Number(Record.subTotal);

    if (Record.discountType === "%") {
      discountValue = (discountPercentage / 100) * subTotal;
    } else if (Record.discountType === "₹") {
      discountValue = discountPercentage;
    }
    let taxValue = (taxing / 100) * (subTotal - discountValue);
    handleFieldChange("discountValue", discountValue.toFixed(2));
    handleFieldChange("taxValue", taxValue.toFixed(2));
    handleFieldChange(
      "Total",
      (subTotal + taxValue - discountValue).toFixed(2)
    );
  }
  function HandleDiscountType(discountType) {
    let discountValue = 0;
    let discountPercentage = Number(Record.discountPercentage);
    let subTotal = Number(Record.subTotal);
    let taxing = Number(Record.taxing);

    if (discountType === "%") {
      discountValue = (discountPercentage / 100) * subTotal;
    } else if (discountType === "₹") {
      discountValue = discountPercentage;
    } else {
      discountValue = 0;
    }
    let taxValue = (taxing / 100) * (subTotal - discountValue);

    console.log("HandleDiscountType", discountValue);

    handleFieldChange("discountValue", discountValue?.toFixed(2));
    handleFieldChange("taxValue", taxValue.toFixed(2));
    handleFieldChange(
      "Total",
      (subTotal + taxValue - discountValue).toFixed(2)
    );
  }
  function HandleTaxValue(taxing) {
    let subTotal = Number(Record.subTotal);
    let discountValue = Number(Record.discountValue);
    taxing = Number(taxing);

    let taxValue = (taxing / 100) * (subTotal - discountValue);

    handleFieldChange("taxValue", taxValue.toFixed(2));
    handleFieldChange(
      "Total",
      (subTotal + taxValue - discountValue).toFixed(2)
    );
  }
  function HandleParticulars(value, index) {
    let items = Record.items;
    const particulars = [...props.items.Invoice_list].filter(
      (item) => item.id == value
    )[0];

    console.log(
      "HandleParticulars",
      Record?.items?.filter((item) => item.invoice_id == value).length > 0,
      Record?.items?.filter((item) => item.invoice_id == value)
    );

    if (Record?.items?.filter((item) => item.invoice_id == value).length > 0) {
      WarningAlert(
        "The invoice is already Chosen, Please choose Different Invoice"
      );
    } else {
      items[index].invoice_id = value;
      items[index].invoice_due_amount = particulars.invoice_due_amount;
      items[index].amount = 0;

      handleFieldChange("items", items);
    }

    TotalCalculation();
  }
  function HandleAttachement(event) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", event.target.files[0]);
    let attachment_url = Record.attachment_url || [];
    UploadFile(attachment_Prefix, bodyFormData).then((res) => {
      if (res.success) {
        handleFieldChange("attachment_url", [...attachment_url, res.data]);
      }
    });
  }

  // get specific record in list
  function GetRecordFromList(List = [], value) {
    List = List.filter((item) => item.value == value);
    if (List.length > 0) {
      return List[0];
    } else {
      return undefined;
    }
  }

  function PrintPDF() {
    GetRecords("/api/v2/paymentreceipt/paymentreceipt/pdf/" + receiptId).then(
      (res) => {
        if (res.success) {
          res = res?.record?.pdf_url || "";
          console.log("paymentreceipt/paymentreceipt/pdf", res);
          if (res != "") {
            window.open(res, "_blank");
          } else {
            console.error("PDF URL is not defined");
          }
        }
      }
    );
  }
  function GetPaidThoughtList() {
    getList({
      listUrl: "/api/v2/paymentreceipt/getbankcashgrouplists/" + companyId,
    }).then((res) => {
      console.log("paymentreceipt/getbankcashgrouplists", res);
      if (res.success) {
        res = res.data || [];
        res = res.map((item) => ({
          ...item,
          value: item.id,
          label: item.ledger_name,
        }));
        console.log("paymentreceipt/getbankcashgrouplists", res);

        HandlePropsChange("deposite_to", {
          deposite_to_list: [...initProps.deposite_to.deposite_to_list, ...res],
        });
      }
      console.log("URL_Account_Customer_Project_List", res);
    });
  }
  function DeleteAttachement(URL) {
    return DeleteRecord("/files/deletefiles?key=" + URL);
  }

  return (
    <AccountsLayout HeaderTitle={"Receipt Form"}>
      <div className="flex flex-col h-full text-[12px] gap-1">
        <FormHeader
          State="Draft"
          FormName="Receipt"
          FormNumber={Record.receipt_no}
          Actions={[
            {
              ButtonName: "Submit",
              type: "primary",
              onClick: Submit,
              visible: receiptId === undefined,
            },
            {
              ButtonName: "Print as PDF",
              type: "primary",
              onClick: PrintPDF,
              visible: receiptId !== undefined,
            },
            {
              ButtonName: "Cancel",
              onClick: () => {
                navigate(-1);
              },
              visible: true,
            },
          ]}
        />
        {/* <FormStage Stages={props.Stages.Stages_list} /> */}
        <div className="flex bg-secondary flex-col items-center flex-grow rounded-lg shadow-[0px_0px_10px_3px_rgba(0,0,0,0.1)] overflow-auto">
          <div className="grid grid-cols-2 gap-2 w-[80%] mt-5">
            <div // customer_id
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.customer_id.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Customer
                {props.customer_id.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <select
                id={"customer_id"}
                className={
                  " w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                  (props.customer_id.mandatory && props.customer_id.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                onChange={(event) => {
                  HandleCustomer(event.target.value);
                }}
                value={Record.customer_id}
                disabled={props.customer_id.readonly}
                aria-placeholder="please select sam"
              >
                {props.customer_id.customer_id_list.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>

            <div // recepit_date
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.recepit_date.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Receipt Date
                {props.recepit_date.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <input
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 font-medium disabled:bg-[#c6c6ca] text-Old_Silver disabled:bg-opacity-20 px-2 py-1 " +
                  (props.recepit_date.mandatory && props.recepit_date.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"recepit_date"}
                type="date"
                value={Record.recepit_date.split("T")[0]}
                disabled={props.recepit_date.readonly}
                onChange={(event) => {
                  handleFieldChange(
                    "recepit_date",
                    event.target.value + "T00:00:00Z"
                  );
                }}
              />
            </div>

            <div // deposite_to
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.deposite_to.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Deposit To
                {props.deposite_to.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <select
                id={"deposite_to"}
                className={
                  " w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                  (props.deposite_to.mandatory && props.deposite_to.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                onChange={(event) => {
                  handleFieldChange("deposite_to", event.target.value);
                  TotalCalculation();
                }}
                value={Record.deposite_to}
                disabled={props.deposite_to.readonly}
                aria-placeholder="please select sam"
              >
                {props.deposite_to.deposite_to_list.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>

            <div // amount
              className={
                "text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.amount.hidden ? " hidden " : " flex ")
              }
            >
              <label className={"w-fit  min-w-[150px] max-w-full"}>
                Amount Received
                {props.amount.mandatory && (
                  <span className="text-[#ff2828] text-[14px] h-fit">*</span>
                )}
              </label>
              <input
                className={
                  "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                  (props.amount.mandatory && props.amount.show
                    ? " rounded-2xl border-2 border-[#ff2828] "
                    : "")
                }
                id={"amount"}
                type="number"
                value={Record.amount}
                disabled={props.amount.readonly}
                onChange={(event) => {
                  handleFieldChange("amount", event.target.value);
                }}
              />
            </div>
          </div>
          <div // items
            className={
              " text-[14px] w-[95%] px-3 mt-4 flex-col items-center gap-1 flex p-2 relative "
            }
          >
            <div className="flex justify-around w-full gap-2 border-y border-Old_Silver border-opacity-35">
              <div className="text-[14px] w-full px-2 py-1">Invoice Number</div>
              <div className="text-[14px] w-full px-2 py-1">
                Invoice Due Amount
              </div>
              <div className="text-[14px] w-full px-2 py-1">Amount</div>
              <span className=" mr-4 rounded-lg  cursor-pointer"></span>
            </div>
            {props.items.Invoice_list.length > 0 ? (
              Record.items.map((item, index) => (
                <div
                  className={
                    " flex justify-around w-full gap-2 border-opacity-35 " +
                    (props?.items?.MandatoryItems[index]
                      ? " border-2 border-[#ff2828] rounded-lg"
                      : " border-b border-Old_Silver ")
                  }
                  key={index}
                >
                  <select // items.invoice_id
                    id={"itemsGroup"}
                    className={
                      " w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-35 outline-none " +
                      (props.items.mandatory && props.items.show
                        ? " rounded-2xl border-2 border-[#ff2828] "
                        : "")
                    }
                    onChange={(event) => {
                      HandleParticulars(event.target.value, index);
                    }}
                    value={item.invoice_id}
                    disabled={props.items.readonly}
                  >
                    <option value={""}>Please Choose</option>
                    {props.items.Invoice_list.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.invoice_no}
                      </option>
                    ))}
                  </select>

                  <input // items.invoice_due_amount
                    className={
                      "w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                      (props.items.mandatory && props.items.show
                        ? " rounded-2xl border-2 border-[#ff2828] "
                        : "")
                    }
                    id={"itemsQuantity"}
                    type="text"
                    value={item.invoice_due_amount}
                    disabled={props.items.readonly}
                    onChange={(event) => {
                      // let items = Record.items;
                      // items[index].Quantity = event.target.value;
                      // let amount = event.target.value * items[index].Rate;
                      // items[index].Amount =
                      //   amount > 0 ? amount : items[index].Rate;
                      // handleFieldChange("items", items);
                      // TotalCalculation();
                    }}
                  />
                  <input // items.Amount
                    className={
                      "w-full border-Old_Silver px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20   outline-none " +
                      (props.items.mandatory && props.items.show
                        ? " rounded-2xl border-2 border-[#ff2828] "
                        : "")
                    }
                    id={"itemsAmount"}
                    type="number"
                    value={item.amount}
                    disabled={props.items.readonly}
                    onChange={(event) => {
                      let items = Record.items;
                      if (
                        Number(event.target.value) >
                        Number(item.invoice_due_amount)
                      ) {
                        WarningAlert("Amount Exceed Due Amount");
                      } else {
                        items[index].amount = event.target.value;
                        handleFieldChange("items", items);
                      }
                      TotalCalculation(items);
                    }}
                  />
                  {option !== "view" && (
                    <span
                      onClick={() => {
                        let items = Record.items;
                        items = items.filter(
                          (itemsitem, itemsindex) => itemsindex != index
                        );
                        handleFieldChange("items", items);
                        TotalCalculation(items);
                      }}
                      className="border h-fit w-fit p-1 rounded-lg hover:bg-tertiary cursor-pointer"
                    >
                      X
                    </span>
                  )}
                </div>
              ))
            ) : (
              <span>
                There are no unpaid invoices associated with this customer
              </span>
            )}
            {option !== "view" && (
              <div className="flex px-2 pb-2 w-full gap-2 border-b border-Old_Silver border-opacity-35">
                <button
                  className={"text-Ocean_Blue hover:drop-shadow-2xl"}
                  onClick={() => {
                    let items = Record.items;
                    items.push({
                      invoice_id: 0,
                      invoice_due_amount: 0,
                      amount: "",
                    });
                    console.log("let items", items);

                    handleFieldChange("items", items);
                  }}
                >
                  Add New Line
                </button>
              </div>
            )}
          </div>

          <div // attachments
            className={
              " text-[14px] w-[95%] px-5 flex-col gap-1 flex py-2 relative "
            }
          >
            <h3 className="text-[14px] ">Attachments</h3>

            <div // attachment_url
              className={
                "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
                (props.attachment_url.hidden ? " hidden " : " flex ")
              }
            >
              {receiptId == undefined && (
                <button
                  className="border px-2 py-1 rounded-lg flex gap-1 items-center"
                  onClick={() => {
                    document.getElementById("FileInput").click();
                  }}
                >
                  <MdOutlineFileUpload className="text-[#20b25b] text-[18px] font-bold" />
                  Upload File
                </button>
              )}
              {Record.attachment_url.length > 0 ? (
                Record.attachment_url.map((attached, index) => (
                  <button
                    key={index}
                    className="cursor-pointer hover:text-[#3f4fff] hover:bg-[#2a3cff] hover:bg-opacity-15 px-2 py-1 rounded-lg flex items-center gap-1 group transition-all duration-100"
                    target="_blank"
                  >
                    <FaRegEye
                      className="group-hover:block hidden transition-all duration-75 hover:bg-[#5ae271] rounded-full"
                      onClick={() => {
                        store.navtotab(
                          baseurl + "/files/preview?key=" + attached
                        );
                      }}
                    />
                    {attached.replaceAll("Journal_/", "")}
                    {receiptId == undefined && (
                      <IoIosCloseCircleOutline
                        className="group-hover:block hidden transition-all duration-75 z-50 hover:bg-red  rounded-full"
                        onClick={() => {
                          let attachment_url = Record.attachment_url || [];

                          DeleteAttachement(attached).then((res) => {
                            if (res.success) {
                              attachment_url = attachment_url.filter(
                                (item, itemindex) => itemindex != index
                              );
                              handleFieldChange("attachment_url", [
                                ...attachment_url,
                              ]);
                              SuccessAlert("Attachment Removed Successfully");
                            } else {
                              WarningAlert("Unable to remove Attachment");
                            }
                          });
                        }}
                      />
                    )}
                  </button>
                ))
              ) : (
                <h3>No Attachments</h3>
              )}
              <input
                type="file"
                onChange={HandleAttachement}
                id="FileInput"
                className="hidden"
              />
            </div>
          </div>

          <div className="flex flex-grow w-[95%] pb-2">
            <div className="w-[45%]">
              <div // notes
                className={
                  "flex text-[14px] w-full px-3 mt-4 flex-col items-start gap-1  " +
                  (props.notes.hidden ? " hidden " : " flex ")
                }
              >
                <textarea
                  className={
                    "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
                    (props.notes.mandatory && props.notes.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  id={"notes"}
                  value={Record.notes}
                  disabled={props.notes.readonly}
                  onChange={(event) => {
                    if (event.target.value.length > 7) {
                      handleFieldChange("notes", event.target.value);
                    } else {
                      handleFieldChange("notes", "Notes: ");
                    }
                  }}
                />
              </div>
              <div // terms_and_conditions
                className={
                  "flex text-[14px] w-full px-3 mt-4 flex-col items-start gap-1  " +
                  (props.terms_and_conditions.hidden ? " hidden " : " flex ")
                }
              >
                <textarea
                  className={
                    "w-full border-b border-Old_Silver border-opacity-35 px-2 py-1 text-Old_Silver disabled:bg-[#c6c6ca] disabled:bg-opacity-20 outline-none " +
                    (props.terms_and_conditions.mandatory &&
                    props.terms_and_conditions.show
                      ? " rounded-2xl border-2 border-[#ff2828] "
                      : "")
                  }
                  id={"terms_and_conditions"}
                  value={Record.terms_and_conditions}
                  disabled={props.terms_and_conditions.readonly}
                  onChange={(event) => {
                    if (event.target.value.length > 20) {
                      handleFieldChange(
                        "terms_and_conditions",
                        event.target.value
                      );
                    } else {
                      handleFieldChange(
                        "terms_and_conditions",
                        "Terms & Conditions: "
                      );
                    }
                  }}
                />
              </div>
            </div>
            <div className="w-[55%] flex flex-col gap-3">
              <div className="flex items-center justify-between px-9 border-b border-Old_Silver border-opacity-35">
                <div className="text-[14px] text-Old_Silver ">
                  {"Amount Received"}
                </div>
                <div className=" w-[200px] text-[16px] font-semibold px-2 py-1 border-Old_Silver ">
                  {Record.amount}
                </div>
              </div>

              <div className="flex items-center justify-between px-9 border-b border-Old_Silver border-opacity-35">
                <div className="text-[14px] text-Old_Silver ">
                  {"Total (₹)"}
                </div>
                <div className=" w-[200px] text-[16px] font-semibold px-2 py-1 border-Old_Silver ">
                  {Record.payment_total}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AccountsLayout>
  );
};

export default ReceiptForm;
