import { useParams } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import { useEffect, useState } from "react";
import {
  GetDateTimeFormat,
  GetRecords,
  RupeeFormat,
  URLPRIFIX,
  WarningAlert,
} from "../../../functions/api";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import AccountsLayout from "../AccountsLayout";

const NestedListView = ({ records, store }) => {
  const { companyId } = useParams();

  const [expandedIds, setExpandedIds] = useState([]);

  const [transactionDetails, setTransactionDetails] = useState({});

  const toggleExpand = (accountname) => {
    setExpandedIds((prev) =>
      prev.includes(accountname)
        ? prev.filter((item) => item !== accountname)
        : [...prev, accountname]
    );
  };

  const renderTransactions = (transactions) => {
    return transactions.map((transaction, index) => (
      <div
        key={index}
        className="flex justify-between w-full cursor-pointer text-[#344cb7] hover:underline"
        onClick={() => {
          switch (transaction.transactiontype_id) {
            case 1:
              store.navTo(
                `/${URLPRIFIX}/${companyId}/accounts/invoice/view/${transaction.transaction_id}`
              );
              break;
            case 2:
              store.navTo(
                `/${URLPRIFIX}/${companyId}/accounts/journal/view/${transaction.transaction_id}/${transaction.transactiontype_id}`
              );
              break;
            case 3:
              store.navTo(
                `/${URLPRIFIX}/${companyId}/accounts/expense/view/${transaction.transaction_id}`
              );
              break;
            case 4:
              store.navTo(
                `/${URLPRIFIX}/${companyId}/accounts/receipt/view/${transaction.transaction_id}`
              );
              break;
            case 7:
              store.navTo(
                `/${URLPRIFIX}/${companyId}/accounts/payable/view/${transaction.transaction_id}`
              );
              break;
            default:
              break;
          }
        }}
      >
        <span className="font-medium">{transaction.referenceno}</span>
        <span className="font-medium">{RupeeFormat(transaction.amount)}</span>
      </div>
    ));
  };

  const renderRecords = (items, level = 0) => {
    return items.map((item) => (
      <div key={item.accountname} className={`pl-${level * 4}`}>
        <div
          className={
            "flex items-center cursor-pointer " +
            (item.accountname == "Current Year Unallocated Earnings"
              ? " text-[#344cb7] hover:underline"
              : " hover:bg-Azureish_White")
          }
          onClick={() => {
            if (item.accountname == "Current Year Unallocated Earnings") {
              store.navTo(`/${URLPRIFIX}/${companyId}/accounts/profitloss`);
            } else {
              toggleExpand(item.accountname);
            }
          }}
        >
          <span className="flex justify-between w-full">
            <span className="font-medium">
              {(item.subCategories?.length > 0 ||
                item.transactionDetails?.length > 0) && (
                <span className="mr-1 text-[12px]">
                  {!expandedIds.includes(item.accountname) ? "▼" : "►"}
                  {/* ◄ ▲ ▼ ►  */}
                </span>
              )}
              {item.accountname}
            </span>
            <span className="font-medium">{RupeeFormat(item.amount)}</span>
          </span>
        </div>
        {!expandedIds.includes(item.accountname) &&
        item?.subCategories?.length > 0 ? (
          <div className="ml-8 ">
            {renderRecords(item.subCategories, level + 1)}
          </div>
        ) : (
          expandedIds.includes(item.accountname) &&
          item?.transactionDetails?.length > 0 && (
            <div className="ml-8 ">
              {renderTransactions(item.transactionDetails, level + 1)}
            </div>
          )
        )}
      </div>
    ));
  };

  return <div>{renderRecords(records)}</div>;
};

const BalanceSheet = () => {
  const { companyId } = useParams();
  const store = useStore();
  const init = {
    assets: [],
    liabilities: [],
    equity: [],
    TotalAssets: [],
    TotalLiabilities: [],
    TotalEquity: [],
    fromDate: "",
    ToDate: "",
  };

  const initProps = {
    add: false,
    delete: false,
    on_off: false,
    print: false,
    update: false,
    view: false,
    assets: {},
    liabilities: {},
    Equity: {},
    equity: {},
    fromDate: {},
    ToDate: {},
  };

  const [Record, setRecord] = useState(init);
  const [props, setprops] = useState(initProps);
  useEffect(() => {
    store.setmodule("balancesheet", init);
    const dateTime = GetDateTimeFormat();

    handleMultiFieldChange({
      fromDate: "1000-01-01T00:00:00Z",
      ToDate: dateTime.DBFormat.substring(0, 10) + "T23:59:59Z",
    });

    loadRecord();
  }, []);

  function loadRecord(fromDate, ToDate) {
    GetRecords(
      `/api/v2/accounting/balancesheet/${companyId}?startDate=${
        fromDate || Record.fromDate
      }&endDate=${ToDate || Record.ToDate}`
    ).then((res) => {
      if (res.success) {
        console.log("accounting/balancesheet", res);
        res = res.record;
        const {
          assets,
          liabilities,
          equity,
          totalAssets,
          totalLiabilities,
          totalEquity,
        } = res;

        handleMultiFieldChange({
          assets,
          liabilities,
          equity,
          TotalAssets: totalAssets,
          TotalLiabilities: totalLiabilities,
          TotalEquity: totalEquity,
        });
      }
    });
  }

  useEffect(() => {
    console.log("Record State change -> ", Record);
    console.log("Props State change -> ", props);
  }, [Record, props]);
  // *Handle Fields changes in Record States
  function handleFieldChange(fieldName, value) {
    setRecord((prev) => {
      return { ...prev, [fieldName]: value };
    });
  }
  function handleMultiFieldChange(value = {}) {
    setRecord((prev) => {
      for (let fieldName in value) {
        prev[fieldName] = value[fieldName];
      }
      return { ...prev };
    });
  }

  const ExportExcel = () => {
    if (
      !Record ||
      (Record.assets.length === 0 && Record.liabilities.length === 0 && Record.equity.length === 0)
    ) {
      WarningAlert("No data available to export.");
      return;
    } else {
      let rows = [];
      const fromDate = new Date(Record.fromDate);
      const toDate = new Date(Record.ToDate);
      const formattedFromDate = fromDate.toISOString().split("T")[0];
      const formattedToDate = toDate.toISOString().split("T")[0];
      rows.push(["", "", ` ${formattedFromDate} - ${formattedToDate} `]);
      rows.push(["", "", "Balance"]);
      rows.push(["ASSETS", "", ""]);
      try {
        Record.assets.forEach((asset) => {
          try {
            rows.push([asset.accountname, "", asset.amount, "", ""]);

            asset.subCategories.forEach((subCategory) => {
              try {
                rows.push([
                  "",
                  `  ${subCategory.accountname}`,
                  subCategory.amount,
                  "",
                  "",
                ]);

                subCategory.subCategories.forEach((subSubCategory) => {
                  try {
                    rows.push([
                      "",
                      `${"   " + "   " + subSubCategory.accountname}`,
                      subSubCategory.amount,
                      "",
                      "",
                    ]);
                  } catch (subSubCategoryError) {
                    console.error(
                      "Error processing subSubCategory:",
                      subSubCategoryError
                    );
                  }
                });
              } catch (subCategoryError) {
                console.error(
                  "Error processing subCategory:",
                  subCategoryError
                );
              }
            });
            rows.push([""]);
          } catch (assetError) {
            console.error("Error processing asset:", assetError);
          }
        });
      } catch (recordAssetsError) {
        console.error("Error processing Record.assets:", recordAssetsError);
      }

      rows.push(["LIABILITIES", "", ""]);

      try {
        Record.liabilities.forEach((liability) => {
          try {
            rows.push([liability.accountname, "", liability.amount]);

            liability.subCategories.forEach((subCategory) => {
              try {
                rows.push([
                  "",
                  `  ${subCategory.accountname}`,
                  subCategory.amount,
                ]);

                subCategory.subCategories.forEach((subSubCategory) => {
                  try {
                    rows.push([
                      "",
                      `${"   " + "   " + subSubCategory.accountname}`,
                      subSubCategory.amount,
                      "",
                      "",
                    ]);
                  } catch (subSubCategoryError) {
                    console.error(
                      "Error processing subSubCategory:",
                      subSubCategoryError
                    );
                  }
                });
              } catch (subCategoryError) {
                console.error(
                  "Error processing subCategory:",
                  subCategoryError
                );
              }
              rows.push([""]);
            });
          } catch (recordAssetsError) {
            console.error(
              "Error processing Record.liabilities:",
              recordAssetsError
            );
          }
        });
      } catch (error) {
        console.error("General error processing Record.liabilities:", error);
      }

      rows.push(["EQUITY", "", ""]);
      try {
        Record.equity.forEach((equity) => {
          try {
            rows.push([equity.accountname, "", equity.amount]);

            equity.subCategories.forEach((subCategory) => {
              try {
                rows.push([
                  "",
                  `  ${subCategory.accountname}`,
                  subCategory.amount,
                ]);

                subCategory.subCategories.forEach((subSubCategory) => {
                  try {
                    rows.push([
                      "",
                      `${"   " + "   " + subSubCategory.accountname}`,
                      subSubCategory.amount,
                      "",
                      "",
                    ]);
                  } catch (subSubCategoryError) {
                    console.error(
                      "Error processing subSubCategory:",
                      subSubCategoryError
                    );
                  }
                });
              } catch (subCategoryError) {
                console.error(
                  "Error processing subCategory:",
                  subCategoryError
                );
              }
              rows.push([""]);
            });
          } catch (equityError) {
            console.error("Error processing Record.equity:", equityError);
          }
        });
      } catch (error) {
        console.error("General error processing Record.equity:", error);
      }

      rows.push(["TOTAL ASSETS", "", Record.TotalAssets]);
      rows.push(["TOTAL LIABILITIES", "", Record.TotalLiabilities]);
      rows.push(["TOTAL EQUITY", "", Record.TotalEquity]);

      const ws = XLSX.utils.aoa_to_sheet(rows);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Balance Sheet");
      XLSX.writeFile(wb, "balance_sheet.xlsx");
    }
  };
  
  const ExportPDF = () => {
    try {
      if (!Record || (Record.assets.length === 0 && Record.liabilities.length === 0 && Record.equity.length === 0)) {
        WarningAlert("No data available to export.");
        return;
      }
      const doc = new jsPDF();
      const data = Record;
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      const HeaderTitle = "Balance Sheet";
      const logo = "/Assets/images/Logo.png";
      const textWidth = doc.getTextWidth(HeaderTitle);
      const pageWidth1 = doc.internal.pageSize.width;
      const xPosition = (pageWidth1 - textWidth) / 2;
      const fromDate = new Date(Record.fromDate);
      const toDate = new Date(Record.ToDate);
      const formattedFromDate = fromDate.toISOString().split("T")[0];
      const formattedToDate = toDate.toISOString().split("T")[0];
      doc.addImage(logo, "PNG", 15, 5, 15, 15);
      const dateRange = `  ${formattedFromDate} - ${formattedToDate}`;
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 66);
      doc.text("ABSOLUTE LEGAL", 8, 25);
      doc.text("LAW FIRM", 15, 30);
      doc.setTextColor(0, 0, 0);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text(HeaderTitle, xPosition, 20);
      doc.setFontSize(11);
      doc.setDrawColor(169, 169, 169);
      doc.setLineWidth(0.2);
      const textWidth1 = doc.getTextWidth(dateRange);
      const textHeight = 8;
      doc.rect(155, 5, textWidth1 + 2, textHeight);
      doc.text(dateRange, 155, 10);
  
      let yPosition = 40;
      const addContentToPage = (text, x, y) => {
        if (y > doc.internal.pageSize.height - 20) { 
          doc.addPage(); 
          y = 10; 
        }
        doc.text(text, x, y);
        return y; 
      };
      doc.setFont("helvetica", "bold");
      doc.text("Assets", 20, yPosition);
      doc.text("Balance", 120, yPosition);
      doc.setLineWidth(0.5);
      doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
      yPosition += 20;
      let totalAssets = 0; 
      doc.setFont("helvetica", "normal");
      data.assets?.forEach((asset) => {
        yPosition = addContentToPage(asset.accountname, 20, yPosition);
        yPosition = addContentToPage(`${asset.amount}`, 120, yPosition);
        doc.setLineWidth(0.1);
        doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
        totalAssets += asset.amount;
        yPosition += 10;
  
        asset.subCategories.forEach((subCategory) => {
          yPosition = addContentToPage(`   ${subCategory.accountname}`, 30, yPosition);
          yPosition =addContentToPage(`${subCategory.amount}`, 120, yPosition);
          doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
          yPosition += 8;
  
          subCategory.subCategories.forEach((subSubCategory) => {
            yPosition = addContentToPage(`   ${subSubCategory.accountname}`, 40, yPosition);
            yPosition = addContentToPage(`${subSubCategory.amount}`, 120, yPosition);
            doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
            yPosition += 8;
          });
        });
      });
      yPosition = addContentToPage("Total Assets:", 20, yPosition);
      yPosition = addContentToPage(Record.TotalAssets.toString(), 120, yPosition);
      doc.setLineWidth(0.1);
      doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
      yPosition += 10;
      doc.setFont("helvetica", "bold");
      doc.setLineWidth(0.5);
      doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
      doc.text("Liabilities", 20, yPosition);
      doc.text("Balance", 120, yPosition);
      yPosition += 10;
      let totalLiabilities = 0;
      doc.setFont("helvetica", "normal");
      data.liabilities.forEach((liability) => {
        doc.setLineWidth(0.1);
        yPosition =  addContentToPage(liability.accountname, 20, yPosition);
        yPosition = addContentToPage(`${liability.amount}`, 120, yPosition);
        doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
        totalLiabilities += liability.amount;
        yPosition += 10;
  
        liability.subCategories.forEach((subCategory) => {
          yPosition = addContentToPage(`${subCategory.accountname}`, 30, yPosition);
          yPosition =  addContentToPage(`${subCategory.amount}`, 120, yPosition);
          doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
          yPosition += 8;
  
          subCategory.subCategories.forEach((subSubCategory) => {
            yPosition =  addContentToPage(`   ${subSubCategory.accountname}`, 40, yPosition);
            yPosition =  addContentToPage(`${subSubCategory.amount}`, 120, yPosition);
            doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
            yPosition += 8;
          });
        });
      });
      yPosition = addContentToPage("Total Liabilities:", 20, yPosition);
      yPosition =  addContentToPage(Record.TotalLiabilities.toString(), 120, yPosition);
      doc.setLineWidth(0.1);
      doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
      yPosition += 10;

      if (data.equity.length > 0) {
        doc.setFont("helvetica", "bold");
        doc.setLineWidth(0.5);
        doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
        doc.text("Equity", 20, yPosition);
        doc.text("Balance", 120, yPosition);
        yPosition += 10;
        let totalEquity = 0;
        doc.setFont("helvetica", "normal");
        data.equity.forEach((equity) => {
          yPosition =  addContentToPage(equity.accountname, 20, yPosition);
          yPosition =  addContentToPage(`${equity.amount}`, 120, yPosition);
          totalEquity += equity.amount;
          doc.setLineWidth(0.1);
          doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
          yPosition += 8;
  
          equity.subCategories.forEach((subCategory) => {
            doc.setLineWidth(0.1);
            yPosition =   addContentToPage(`   ${subCategory.accountname}`, 40, yPosition);
            yPosition =  addContentToPage(`${subCategory.amount}`, 120, yPosition);
            doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
            yPosition += 8;
          });
        });
  
        
        yPosition = addContentToPage("Total Equity:", 20, yPosition);
        yPosition = addContentToPage(Record.TotalEquity.toString(), 120, yPosition);
        doc.setLineWidth(0.1);
        doc.line(10, yPosition + 2, pageWidth - 10, yPosition + 2);
        yPosition += 10;
      }
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        const pageNumber = `Page ${i}`;
        const pageNumberX = (pageWidth - doc.getTextWidth(pageNumber)) / 2; 
        doc.setFontSize(10);
        doc.text(pageNumber, pageNumberX, pageHeight - 10);
      }
      doc.save("Balance_Sheet.pdf");
  
    } catch (error) {
      console.error("Error generating PDF:", error);
      WarningAlert("An error occurred while generating the PDF.");
    }
  };

  return (
    <AccountsLayout HeaderTitle={"Balance Sheet"}>
      <div className="flex px-10 py-1 bg-secondary rounded-lg gap-3 justify-between border-b border-Old_Silver">
        <div // ToDate
          className={
            "flex text-[14px] w-full h-full px-3 flex-row items-center justify-center gap-3 " +
            (props.ToDate.hidden ? " hidden " : " flex ")
          }
        >
          <label className={"w-fit  min-w-[100px] max-w-full"}>
            As of Date
            {props.ToDate.mandatory && (
              <span className="text-[#ff2828] text-[14px] h-fit">*</span>
            )}
          </label>
          <input
            className={
              "w-[250px] border-b border-Old_Silver border-opacity-35 font-medium disabled:bg-[#c6c6ca] text-Old_Silver disabled:bg-opacity-20 px-2 py-1 " +
              (props.ToDate.mandatory && props.ToDate.show
                ? " rounded-2xl border-2 border-[#ff2828] "
                : "")
            }
            id={"ToDate"}
            type="date"
            value={Record.ToDate.split("T")[0]}
            disabled={props.ToDate.readonly}
            onChange={(event) => {
              handleFieldChange("ToDate", event.target.value + "T23:59:59Z");
              loadRecord(Record.fromDate, event.target.value + "T23:59:59Z");
            }}
          />
        </div>
        <div className="flex gap-5">
          <button
            className={
              "py-2 px-6 rounded-xl font-bold text-[12px]  bg-primary text-secondary"
            }
            onClick={ExportExcel}
          >
            EXCEL
          </button>
          <button
            className={
              "py-2 px-6 rounded-xl font-bold text-[12px]  bg-primary text-secondary"
            }
            onClick={ExportPDF}
          >
            PDF
          </button>
        </div>
      </div>
      <div className="flex h-[90%] px-10 pt-2 bg-secondary rounded-lg">
        <div className="w-[50%] p-2 ">
          <div className="h-[95%] ">
            <h1 className="font-bold text-[18px]">Assets</h1>
            <div className=" h-[90%] overflow-auto p-1">
              <NestedListView records={Record.assets} store={store} />
            </div>
          </div>
          <div className="h-[5%] font-semibold ">
            {"Total Assets : " + RupeeFormat(Record.TotalAssets)}
          </div>
        </div>
        <div className="w-[50%] p-2 ">
          <div className="h-[95%]">
            <h1 className="font-bold text-[18px]">Liabilities</h1>
            <div className=" h-fit overflow-auto p-1">
              <NestedListView records={Record.liabilities} store={store} />
            </div>
            <h1 className="font-bold text-[18px]">Equity</h1>
            <div className=" h-fit overflow-auto p-1">
              <NestedListView records={Record.equity} store={store} />
            </div>
          </div>
          <div className="h-[5%] font-semibold ">
            {"Total Liabilities & Equity : " +
              RupeeFormat(
                Number(Record.TotalLiabilities) + Number(Record.TotalEquity)
              )}
          </div>
        </div>
      </div>
    </AccountsLayout>
  );
};

export default BalanceSheet;
