import Dropdown from "../../Components/Fields/Dropdown";
import {
  CheckMandatory,
  ErrorAlert,
  getList,
  GetListDropdown,
  GetRecords,
  saveRecord,
  SuccessAlert,
  UpdateRecord,
  URLPRIFIX,
  WarningAlert,
} from "../../functions/api";
import React, { useEffect, useState } from "react";
import { resolvePath, useLocation, useParams } from "react-router-dom";
import String from "../../Components/Fields/String";
import { useStore } from "../../Components/StateProvider";
import FormView from "../../Components/Elements/FormView";
import ListView from "../../Components/Elements/ListView";
import DatePicker from "../../Components/Fields/DatePicker";
import Journal from "../../Components/Fields/Journal";
import { getlocal } from "../../functions/localstorage";
import Attachement from "../../Components/Fields/Attachement";
import FileInput from "../../Components/Fields/FileInput";
import { PrimaryBTN, secondaryBTN } from "../../Constancs/cssconst";

const ProjectForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { option, id } = useParams();
  const user = getlocal("system")?.user || {};
  const tablename = "project";
  const getrecord = `/project/getproject/${id}/11`;
  const updaterecord = "/project/updateproject/" + id + "/11";
  const setrecord = "/project/createproject/11";
  const [GenerateInvoice, setGenerateInvoice] = useState(false);
  const [CompanyNumber, setCompanyNumber] = useState("");
  const [CompanyList, setCompanyList] = useState([
    { label: "Please Choose", value: "" },
  ]);

  const store = useStore();
  const init = {
    projectName: "",
    projectDescription: "",
    startDate: "",
    endDate: "",
    priority: "",
    status: "Draft",
    film_id: "0",
    category_id: "",
    contact_id: "",
    activeStatus: true,
    child: [],
    attachmenturl: [],
  };

  useEffect(() => {
    console.log("queryParams", queryParams.get("projectName") === null);

    store
      .setmodule(tablename, init)
      .then((res) => console.log("autofill check", res));
    if (id != undefined) {
      store.setheader(option.toUpperCase() + " " + tablename);
      store.setprops("activeStatus", { hidden: false });
      store.setprops("category_id", { hidden: false, readonly: true });

      // props for the Mandatory field
      store.setprops(
        "projectName",
        queryParams.get("projectName")
          ? {
              mandatory: queryParams.get("projectName").includes("mandatory"),
              readonly: queryParams.get("projectName").includes("readonly"),
              hidden: queryParams.get("projectName").includes("hidden"),
            }
          : { mandatory: false }
      );
      store.setprops(
        "contact_id",
        queryParams.get("contact_id")
          ? {
              mandatory: queryParams.get("contact_id").includes("mandatory"),
              readonly: queryParams.get("contact_id").includes("readonly"),
              hidden: queryParams.get("contact_id").includes("hidden"),
            }
          : { mandatory: false, readonly: true }
      );
      store.setprops(
        "startDate",
        queryParams.get("startDate")
          ? {
              mandatory: queryParams.get("startDate")?.includes("mandatory"),
              readonly: queryParams.get("startDate")?.includes("readonly"),
              hidden: queryParams.get("startDate")?.includes("hidden"),
            }
          : { mandatory: false }
      );
      store.setprops(
        "endDate",
        queryParams.get("endDate")
          ? {
              mandatory: queryParams.get("endDate")?.includes("mandatory"),
              readonly: queryParams.get("endDate")?.includes("readonly"),
              hidden: queryParams.get("endDate")?.includes("hidden"),
            }
          : { mandatory: false }
      );
      store.setprops(
        "film_id",
        store.getvalue("film_id") != 0
          ? queryParams.get("film_id")
            ? {
                mandatory: queryParams.get("film_id")?.includes("mandatory"),
                readonly: queryParams.get("film_id")?.includes("readonly"),
                hidden: queryParams.get("film_id")?.includes("hidden"),
              }
            : { mandatory: false, readonly: true }
          : { mandatory: false, hidden: true }
      );
      store.setprops(
        "projectDescription",
        queryParams.get("projectDescription")
          ? {
              mandatory: queryParams
                .get("projectDescription")
                ?.includes("mandatory"),
              readonly: queryParams
                .get("projectDescription")
                ?.includes("readonly"),
              hidden: queryParams.get("projectDescription")?.includes("hidden"),
            }
          : { mandatory: false }
      );
      store.setprops(
        "priority",
        queryParams.get("priority")
          ? {
              mandatory: queryParams.get("priority")?.includes("mandatory"),
              readonly: queryParams.get("priority")?.includes("readonly"),
              hidden: queryParams.get("priority")?.includes("hidden"),
            }
          : { mandatory: false }
      );

      GetRecords(getrecord).then(async (res) => {
        console.log(tablename + " Data - ", res, res.record);
        if (res.success) {
          let records = {
            ...init,
            projectName: res.record.parent.projectName,
            projectDescription: res.record.parent.projectDescription,
            startDate: res.record.parent.startDate,
            endDate: res.record.parent.endDate,
            priority: res.record.parent.priority,
            status: res.record.parent.status,
            film_id: res.record.parent.film_id,
            contact_id: res.record.parent.contact_id,
            category_id: res.record.parent.category_id,
            activeStatus: res.record.parent.activeStatus,
            attachmenturl: res.record.parent.attachmenturl || [],
          };

          if (option === "view") {
            store.setprops("projectName", { hidden: false, readonly: true });
            store.setprops("projectDescription", {
              hidden: false,
              readonly: true,
            });
            store.setprops("startDate", { hidden: false, readonly: true });
            store.setprops("endDate", { hidden: false, readonly: true });
            store.setprops("priority", { hidden: false, readonly: true });
            store.setprops("status", { hidden: false, readonly: true });
            store.setprops(
              "film_id",
              res.record.parent.category_id == 1
                ? { readonly: true }
                : { hidden: true }
            );
            store.setprops("contact_id", { hidden: false, readonly: true });
            store.setprops("category_id", { hidden: false, readonly: true });
            store.setprops("activeStatus", { hidden: false, readonly: true });
            records.child = [
              {
                list: res.record.child,
                header: [
                  ["Document Title", "task_title"],
                  ["Description", "description"],
                  ["Priority", "priority"],
                  ["Due Date", "dueDate"],
                  ["Status", "status"],
                  ["Action", "ACTION"],
                ],
                table: "Documents",
              },
            ];
          } else if (option === "edit") {
            store.setprops(
              "film_id",
              res.record.parent.category_id == 1
                ? { readonly: true }
                : { hidden: true }
            );
            store.setprops("status", { hidden: false, readonly: false });
          }
          console.log(tablename + " Data - ", records);

          store.reset(records);
        }
      });
    } else {
      store.setheader("CREATE NEW " + tablename);
      store.setprops("category_id", { mandatory: true, hidden: false });
      store.setprops("activeStatus", { hidden: true });
      store.setprops("status", { hidden: true });
      // store.setvalue("status", "Draft");
      // props for the Mandatory field
      store.setprops(
        "projectName",
        queryParams.get("projectName")
          ? {
              mandatory: queryParams.get("projectName").includes("mandatory"),
              readonly: queryParams.get("projectName").includes("readonly"),
              hidden: queryParams.get("projectName").includes("hidden"),
            }
          : { mandatory: true }
      );
      store.setprops(
        "contact_id",
        queryParams.get("contact_id")
          ? {
              mandatory: queryParams.get("contact_id").includes("mandatory"),
              readonly: queryParams.get("contact_id").includes("readonly"),
              hidden: queryParams.get("contact_id").includes("hidden"),
            }
          : { mandatory: true }
      );
      store.setprops(
        "startDate",
        queryParams.get("startDate")
          ? {
              mandatory: queryParams.get("startDate")?.includes("mandatory"),
              readonly: queryParams.get("startDate")?.includes("readonly"),
              hidden: queryParams.get("startDate")?.includes("hidden"),
            }
          : { mandatory: true }
      );
      store.setprops(
        "endDate",
        queryParams.get("endDate")
          ? {
              mandatory: queryParams.get("endDate")?.includes("mandatory"),
              readonly: queryParams.get("endDate")?.includes("readonly"),
              hidden: queryParams.get("endDate")?.includes("hidden"),
            }
          : { mandatory: true }
      );
      store.setprops(
        "film_id",

        queryParams.get("film_id")
          ? {
              mandatory: queryParams.get("film_id")?.includes("mandatory"),
              readonly: queryParams.get("film_id")?.includes("readonly"),
              hidden: queryParams.get("film_id")?.includes("hidden"),
            }
          : store.getvalue("category_id") == "1"
          ? {
              mandatory: true,
              hidden: false,
            }
          : {
              mandatory: false,
              hidden: true,
            }
      );
      store.setprops(
        "priority",
        queryParams.get("priority")
          ? {
              mandatory: queryParams.get("priority")?.includes("mandatory"),
              readonly: queryParams.get("priority")?.includes("readonly"),
              hidden: queryParams.get("priority")?.includes("hidden"),
            }
          : { mandatory: false }
      );
      store.setprops(
        "projectDescription",
        queryParams.get("projectDescription")
          ? {
              mandatory: queryParams
                .get("projectDescription")
                ?.includes("mandatory"),
              readonly: queryParams
                .get("projectDescription")
                ?.includes("readonly"),
              hidden: queryParams.get("projectDescription")?.includes("hidden"),
            }
          : { mandatory: false, readonly: false }
      );
    }
    GetListDropdown({
      listUrl: "/clientcategory/getClientCategorylist",
      field: "categoryName",
      sort: "asc",
      count: 100,
      store: store,
    });
    GetClientList_From_Contacts();
  }, [option, id]);

  function update() {
    store.startLoading();
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.id = Number(id);
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    if (CheckDueDates(data.startDate, data.endDate)) {
      UpdateRecord(updaterecord, data).then((res) => {
        if (res.success) {
          store.stopLoading();
          SuccessAlert("Project Updated Successfully");
          // store.navback();
          // store.reset(init);
        }
        store.stopLoading();
      });
    }
    store.stopLoading();
  }

  function Submit() {
    store.startLoading();
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.createdBy = store.getuser().id;
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    console.log("save data - ", data);
    if (
      CheckMandatory(tablename, store) &&
      CheckDueDates(data.startDate, data.endDate)
    ) {
      saveRecord(setrecord, data).then((res) => {
        console.log("Resonce of saveRecord - ", res);
        if (res.success) {
          store.stopLoading();
          SuccessAlert("Project Submitted Successfully");
          store.navTo(
            "/" + URLPRIFIX + "/projects/view/" + res.Id.projectDetails.id,
            { replace: true }
          );
          store.reset(init);
        } else {
          store.stopLoading();
          ErrorAlert(
            res?.errormessage?.response?.data ||
              " Error Found Please contact the Admin"
          );
        }
        store.stopLoading();
      });
    }
  }

  function CheckDueDates(projectdate = "", dueDate = "") {
    let out = true;
    if (projectdate != "" && dueDate != "") {
      if (new Date(projectdate) > new Date(dueDate)) {
        WarningAlert("The start date must be earlier than the end date.");
        out = false;
        store.stopLoading();
      }
    }
    return out;
  }

  function categoryNameChange(category_id) {
    GetClientList_From_Contacts({}, category_id);

    category_id = store
      .getprops("categoryName_list")
      .find((item) => item.value == category_id);
    if (category_id != undefined && category_id.id == "1") {
      GetFilmList_From_Contacts();
      store.setprops(
        "film_id",
        queryParams.get("film_id")
          ? {
              mandatory: queryParams.get("film_id")?.includes("mandatory"),
              readonly: queryParams.get("film_id")?.includes("readonly"),
              hidden: false,
            }
          : { mandatory: true, hidden: false }
      );
    } else {
      store.setprops("film_id", { mandatory: false, hidden: true });
    }
    store.setvalue("film_id", "0");
  }
  function FilmNameChange(film_id) {
    GetClientList_From_Contacts(
      store.getprops("film_name_list").find((item) => item.value == film_id)
    );
  }
  function ClientNameChange(client_id) {
    let films = [];
    let client = {};
    // Get Client details by the Client_id
    store.getprops("clientname_list").map((item) => {
      if (item.value == client_id) {
        client = item;
      }
    });
    //Get flim list
    !store.getprops("film_id")?.hidden && GetFilmList_From_Contacts(client);
  }

  async function GetClientList_From_Contacts(
    film = {},
    category_id = store.getvalue("category_id")
  ) {
    console.log("FilmNameChange category_id", category_id);

    if (category_id == "1") {
      await getList({
        listUrl: "/contact/getcontacts",
        count: 10000,
        field: "film_name",
        search: film.label,
      }).then((contacts) => {
        if (contacts.success) {
          contacts = contacts?.data?.list || [];
          console.log("FilmNameChange contacts- -", contacts);
          let clientList = [];
          contacts
            .map((contact) => {
              if (
                contact.client_id != undefined &&
                contact.clientName != undefined &&
                contact.client_id != "" &&
                contact.clientName != "" &&
                !clientList.filter(
                  (client) => client.label === contact.clientName
                ).length > 0
              ) {
                clientList.push({
                  value: contact.client_id,
                  label: contact.clientName,
                });
              }
            })
            .filter((item) => item != undefined);
          console.log("FilmNameChange clientList- -", clientList);

          store.setprops("clientname_list", clientList);

          // get film information form getfilms
        }
      });
    } else {
      await getList({
        listUrl: "/api/v2/Customer/getclients",
        count: 10000,
      }).then((clients) => {
        if (clients.success) {
          clients = clients?.data?.list || [];
          console.log("FilmNameChange clients- -", clients);
          let clientList = [];
          clients = clients.map((client) => ({
            value: client.id,
            label: client.clientname,
          }));
          console.log("FilmNameChange clientList- -", clients);
          store.setprops("clientname_list", clients);
        }
      });
    }
  }
  async function GetFilmList_From_Contacts(client = {}) {
    await getList({
      listUrl: "/contact/getcontacts",
      count: 10000,
      field: "clientName",
      search: client.label,
    }).then((contacts) => {
      if (contacts.success) {
        let flimList = [];
        contacts = contacts?.data?.list || [];
        console.log("ClientNameChange contacts- -", contacts);
        contacts.map((contact) => {
          if (
            !flimList.filter((film) => film.film_name === contact.film_name)
              .length > 0
          ) {
            flimList.push(contact);
          }
        });
        console.log("ClientNameChange flimList-1 -", flimList);

        // get film information form getfilms
        getList({
          listUrl: "/api/FilmMaster/getfilms",
          field: "film_name",
          sort: "asc",
          count: 1000,
        }).then((Films) => {
          if (Films.success) {
            Films = Films?.data?.list || [];
            console.log("ClientNameChange Films- -", Films);

            flimList = flimList.map((film) => {
              film = Films.find((item) => film.film_name === item.film_name);
              return { value: film.id, label: film.film_name };
            });
            store.setprops("film_name_list", flimList);
          }
        });
      }
    });
  }
  function NavigateToInvoice(company_id) {
    let contact_id = store.getvalue("contact_id");
    console.log("GenerateInvoice contact_id ", contact_id);
    GetRecords("/contact/contacts/" + contact_id).then((res) => {
      if (res.success) {
        res = res?.record?.parent || [];
        if (res.length > 0) {
          res = res[0].client_id;
          if (res != null || res != undefined) {
            store.navTo(
              "/" +
                URLPRIFIX +
                "/" +
                company_id +
                "/accounts/invoice/create?proj_id=" +
                id +
                "&cust_id=" +
                res
            );
          } else {
            ErrorAlert(
              "No Client Name Found, Please update the contact with proper Client Name"
            );
          }
        }
      }
    });
  }

  return (
    <FormView
      actions={[
        {
          Name: "Back",
          onclick: () => {
            // store.navTo("/" + URLPRIFIX + "/projects/list");
            store.navback();
            store.reset(init);
          },
          visible: true,
        },
        {
          Name: "Generate Invoice",
          onclick: () => {
            // api/v2/Customer/getclient/2
            GetRecords(
              "/contact/contacts/" + store.getvalue("contact_id")
            ).then((res) => {
              if (res.success) {
                res = res?.record?.parent[0] || {};
                res = res.client_id;
                GetRecords("/api/v2/Customer/getclient/" + res).then(
                  (client) => {
                    if (client.success) {
                      client = client?.record || {};
                      client = client?.company_id?.map((item) => ({
                        value: item.company_id,
                        label: item.company_name,
                      }));
                      setCompanyList((prev) => {
                        return [...prev, ...client];
                      });
                    }
                    console.log("Generate Invoice", client);
                  }
                );
              }
            });

            setGenerateInvoice(true);
          },
          type: "primary",
          visible: option === "view" && user.role === 1,
        },
        {
          Name: "Submit",
          onclick: Submit,
          type: "primary",
          visible: id === undefined,
        },
        {
          Name: "Update",
          onclick: update,
          type: "primary",
          visible: option === "edit" && store.getprops("update"),
        },
        {
          Name: "Create Document",
          type: "primary",
          onclick: () => {
            let data = store.getrecord();
            console.log("autofill check On Create Project - ", data, id);

            store.setrecord("document", {
              project_id: id,
              form_id: "",
              film_id: data.film_id,
              contact_id: data.contact_id,
              assigned_to: "",
              task_title: "",
              dueDate: "",
              priority: "",
              editor_id: "",
              status: "Draft",
              description: "",
              attachmenturl: [],
              activeStatus: true,
              child: [],
            });
            store.navTo(
              "/demo/documents/create?project_id=readonly&film_id=readonly&contact_id=readonly"
            );
          },
          visible: option === "view" && getlocal("properties")?.document?.add,
        },
      ]}
    >
      {GenerateInvoice && (
        <div className="absolute flex h-[80%] w-[95%] rounded-lg z-50 bg-black bg-opacity-15 backdrop-blur-sm justify-center items-center">
          <div className="flex flex-col items-center bg-secondary px-20 py-5 rounded-lg">
            <h1 className="font-bold">Please confirm the Company</h1>
            <div className="flex flex-col pt-6 pb-10 w-[80%]">
              <label>Company Name</label>
              <select
                className=" flex w-full border rounded-lg"
                onChange={(event) => {
                  setCompanyNumber(event.target.value);
                }}
              >
                {CompanyList.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex justify-evenly w-full">
              <button
                className={secondaryBTN}
                onClick={() => {
                  setGenerateInvoice(false);
                }}
              >
                Back
              </button>
              <button
                className={PrimaryBTN}
                onClick={() => {
                  if (CompanyNumber != "") {
                    NavigateToInvoice(CompanyNumber);
                  } else {
                    ErrorAlert(
                      "Please choose the company for generate invoice."
                    );
                  }
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="grid grid-cols-2 max-md:grid-cols-1 gap-6">
        <String name="projectName" label="Project Name :" />
        <Dropdown
          name="category_id"
          label="Catagory :"
          list={store.getprops("categoryName_list")}
          onchange={categoryNameChange}
        />

        <Dropdown
          name="contact_id"
          label="Customer :"
          list={store.getprops("clientname_list")}
          onchange={ClientNameChange}
        />
        <Dropdown
          name="film_id"
          label="Film :"
          list={store.getprops("film_name_list")}
          onchange={FilmNameChange}
          onAdd={() => {
            store.navTo("/" + URLPRIFIX + "/filmmaster/create");
          }}
        />
        <Dropdown
          name="priority"
          label="Priority :"
          list={[
            { value: "High", label: "High" },
            { value: "Medium", label: "Medium" },
            { value: "Low", label: "Low" },
          ]}
        />
        <Dropdown
          name="status"
          label="Status :"
          list={[
            { value: "Draft", label: "Draft" },
            { value: "inprogress", label: "InProgress" },
            { value: "completed", label: "Completed" },
            { value: "billed", label: "Billed" },
          ]}
        />
        <Dropdown
          name="activeStatus"
          label="Active Status :"
          list={[
            { value: true, label: "Active" },
            { value: false, label: "InActive" },
          ]}
        />
        <div className="col-span-2 max-md:col-span-1">
          <Journal
            name="projectDescription"
            label="Project Description :"
            doubleline={true}
          />
        </div>
        <DatePicker name="startDate" label="Start Date :" />
        <DatePicker name="endDate" label="End Date :" />
        <div className="col-span-2 max-md:col-span-1 px-2">
          <div className="font-bold text-[20px]">Attachements</div>
          <div className="grid grid-cols-5">
            {store.getvalue("attachmenturl")?.length > 0 ? (
              store.getvalue("attachmenturl").map((item, index) => {
                // console.log("test 223", item);
                return (
                  <div className="col-span-1 flex border-r pr-6 w-full  items-center">
                    <Attachement name={"attachmenturl"} fileurl={item} />
                    <button
                      className="hover:bg-tertiary p-1 h-fit w-fit rounded-full"
                      onClick={() => {
                        let arr = [];
                        store
                          .getvalue("attachmenturl")
                          .map((attachitem, attachindex) => {
                            if (attachindex !== index) {
                              arr.push(attachitem);
                            }
                          });
                        store.setvalue("attachmenturl", arr);
                      }}
                    >
                      X
                    </button>
                  </div>
                );
              })
            ) : (
              <>No Data</>
            )}
            {option !== "view" && (
              <FileInput
                prefix={"TASK" + id}
                onchange={(file) => {
                  let attachmenturl = store.getvalue("attachmenturl") || [];
                  attachmenturl.push(file);
                  store.setvalue("attachmenturl", attachmenturl);
                }}
              />
            )}
          </div>
        </div>

        <div className="col-span-2 max-md:col-span-1 px-2">
          {store.getvalue("child") &&
            store.getvalue("child").map((child) => (
              <>
                {" "}
                <div className="font-bold text-[20px]">
                  {child.table.toUpperCase()}
                </div>
                <ListView
                  Tablehead={
                    "min-w-[150px] pr-[10px] max-sm:text-[26px] md:text-[20px] lg:text-[16px] xl:text-[20px] font-bold"
                  }
                  Tablerecord="pr-[10px]  max-sm:text-[26px] md:text-[20px] lg:text-[12px] xl:text-[16px] cursor-pointer "
                  onview={(index) => {
                    store.navTo("/demo/documents/view/" + index);
                  }}
                  height={"fit"}
                  header={child.header}
                  records={child.list}
                />
              </>
            ))}
        </div>
      </div>
    </FormView>
  );
};

export default ProjectForm;
