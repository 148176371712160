import { createContext, useContext, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { getFulllocal, setlocal } from "../functions/localstorage";

const storeContext = createContext();

const init = {
  system: { login: "" },
  records: {},
  properties: {},
};

const actiontype = {
  Login: "Login",
  Logout: "Logout",
  loadstate: "LOAD_STATE",
  changefield: "changefield",
  changefieldofModule: "changefieldofModule",
  changeprops: "changeprops",
  changepropsofmodule: "changepropsofmodule",
  systemchangefield: "systemchangefield",
  setmenu: "setmenu",
  reset: "reset",
};

const reducer = (state, action) => {
  // console.log("Reducer action - ",action,state);

  switch (action.type) {
    case actiontype.Login:
      state.system.login = true;
      setlocal(state);
      return { ...state };
    case actiontype.Logout:
      state.system = { login: false };
      state.records = {
        Loginpage: {
          username: "",
          password: "",
          devicetoken: "",
        },
      };
      state.properties = {};
      setlocal(state);
      return { ...state };
    case actiontype.changefield:
      state.records[state.system.module][action.field] = action.value;
      setlocal(state);
      return { ...state };
    case actiontype.changeprops:
      if (state.properties[state.system.module] === undefined) {
        state.properties[state.system.module] = {};
      }
      state.properties[state.system.module][action.field] = action.value;
      setlocal(state);
      return { ...state };
    case actiontype.changepropsofmodule:
      if (state.properties[action.module] === undefined) {
        state.properties[action.module] = {};
      }
      state.properties[action.module][action.field] = action.value;
      // setlocal(state);
      return { ...state };
    case actiontype.changefieldofModule:
      if (state.records[action.module] === undefined) {
        state.records[action.module] = {};
      }
      state.records[action.module][action.field] = action.value;
      // setlocal(state);
      return { ...state };
    case actiontype.setmenu:
      state.records.menu = action.value;
      // setlocal(state);
      return { ...state };
    case actiontype.systemchangefield:
      state.system[action.field] = action.value;
      setlocal(state);
      return { ...state };
    case "header":
      state.system.header = action.value;
      setlocal(state);
      return { ...state };
    case actiontype.reset:
      state.records[state.system.module] = action.record;
      // setlocal(state);
      return { ...state };
    case actiontype.loadstate:
      state = action.state;
      setlocal(state);
      return { ...state };
    default:
      // setlocal(state);
      return { ...state };
  }
};

// initialize the state for the global states
export const StoreProvider = ({ children }) => {
  // patten followed
  // 3 major parts of states system, records, properties
  //    {
  //         system : {
  //             popups:[]
  //         },
  //         records :{
  //             modulename:{
  //                 field:value
  //             }
  //         },
  //         properties : {
  //             modulename:{
  //                 field: {
  //                     props: value
  //                 }
  //             }
  //         }
  //     }
  const [state, dispatch] = useReducer(reducer, init);
  const route = useNavigate();

  function getvalue(field) {
    let mod = getmodule();
    return (
      state && state.records && state.records[mod] && state.records[mod][field]
    );
  }
  function getmenu() {
    return state && state.records && state.records.menu;
  }
  function setmenu(value) {
    dispatch({
      type: actiontype.setmenu,

      value: value,
    });
  }
  function setvalue(field, value) {
    dispatch({ type: actiontype.changefield, field: field, value: value });
  }
  function getsystemvalue(field) {
    return field === undefined
      ? state && state.system
      : state && state.system && state.system[field];
  }
  function gettoken() {
    return state && state.system && state.system.token;
  }
  function setsystemvalue(field, value) {
    dispatch({
      type: actiontype.systemchangefield,
      field: field,
      value: value,
    });
  }
  function getprops(field) {
    let value =
      state &&
      state.records &&
      state.properties[getmodule()] &&
      state.properties[getmodule()][field];
    return value;
  }
  function getallprops() {
    let value = state && state.records && state.properties;
    return value;
  }
  function setprops(field, value) {
    dispatch({ type: actiontype.changeprops, field: field, value: value });
  }
  function setpropsofmodule(module, field, value) {
    dispatch({
      type: actiontype.changepropsofmodule,
      module: module,
      field: field,
      value: value,
    });
  }
  function setstate(state) {
    dispatch({ type: "LOAD_STATE", state: state });

    return true;
  }
  function getmodule() {
    return state && state.system && state.system.module;
  }
  function removemodule(module) {
    let storedState = JSON.parse(localStorage.getItem("appState"));
    let record = storedState.records;
    let newmodules = {};
    for (let label in record) {
      if (label !== module) {
        newmodules[label] = record[label];
      }
    }
    storedState.records = newmodules;

    dispatch({ type: "LOAD_STATE", state: storedState });
    return true;
  }

  async function setmodule(module, props) {
    let storedState = JSON.parse(localStorage.getItem("appState"));
    if (storedState && Object.keys(storedState.records).includes(module)) {
      storedState.system.module = module;
      // console.log("module Avaliable load");
      dispatch({ type: "LOAD_STATE", state: storedState });
      return false;
    } else {
      // console.log("No module set load", module, props);
      storedState === null &&
        (storedState = {
          system: {},
          records: {},
          properties: {},
        });
      storedState.system.module = module;
      storedState.records[module] = props;
      // console.log("No module set load", storedState);

      dispatch({ type: "LOAD_STATE", state: storedState });
      return true;
    }
  }
  function reset(props) {
    // console.log("Reset Happning");

    dispatch({ type: actiontype.reset, record: props });
  }
  function login() {
    dispatch({ type: actiontype.Login });
  }
  function logout() {
    dispatch({ type: actiontype.Logout });
    navTo("/login");
  }
  // function navTo(link) {
  //   route(link);
  // }
  const navTo = route

  function navtotab(link) {
    window.open(link, "_blank");
  }
  function navback() {
    route(-1);
  }
  function getLogin() {
    return state && state.system && state.system.login;
  }
  function getheader() {
    return (state && state.system && state.system.header) || "";
  }
  function setheader(head) {
    console.log("Setheader", head);

    dispatch({ type: "header", value: head });
  }
  function getrecord(module) {
    return module === undefined
      ? state && state.records && state.records[state.system.module]
      : state && state.records && state.records[module];
  }
  function setrecord(module, record) {
    state.records[module] = record;
    console.log("autofill check state", state);

    dispatch({ type: "LOAD_STATE", state: state });
  }
  function getuser() {
    return (state && state.system && state.system.user) || "";
  }
  function setvaluetomodule(module, field, value) {
    dispatch({
      type: actiontype.changefieldofModule,
      module: module,
      field: field,
      value: value,
    });
  }
  function getvaluetomodule(module, field) {
    return (
      state &&
      state.records &&
      state.records[module] &&
      state.records[module][field]
    );
  }
  function startLoading() {
    setsystemvalue("loading", true);
  }
  function stopLoading() {
    console.log("stopLoading");

    setsystemvalue("loading", false);
  }

  return (
    <storeContext.Provider
      value={{
        state,
        getvalue,
        setvalue,
        gettoken,
        getmodule,
        getprops,
        getallprops,
        setstate,
        setprops,
        reset,
        setmodule,
        navTo,
        navback,
        login,
        logout,
        getLogin,
        getsystemvalue,
        setsystemvalue,
        setheader,
        getheader,
        setmenu,
        getmenu,
        getrecord,
        setrecord,
        getuser,
        navtotab,
        setvaluetomodule,
        getvaluetomodule,
        setpropsofmodule,
        removemodule,
        startLoading,
        stopLoading,
      }}
    >
      {children}
    </storeContext.Provider>
  );
};

export const useStore = () => useContext(storeContext);
