import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { getList, URLPRIFIX } from "../../functions/api";
import ListView from "../Elements/ListView";
import { contentHeader } from "../../Constancs/cssconst";
import { IoChevronForwardOutline } from "react-icons/io5";
import { getlocal } from "../../functions/localstorage";
import { Slide, toast, ToastContainer } from "react-toastify";

const Header = ({ store, setsider }) => {
  const user = getlocal("system")?.user || {};

  const [isPopupVisible, setPopupVisible] = useState({
    notification: false,
    profile: false,
  });
  useEffect(() => {
    getNotification();
  }, []);

  function getNotification() {
    getList({
      listUrl: "/api/SentMessage/byuserid/" + user.id,
    }).then((res) => {
      console.log("notification", res);
      store.setsystemvalue("Notification", res?.data);
    });
  }
  const togglePopup = (field) => {
    let newfield = {};
    if (field === "notification") {
      newfield = {
        notification: !isPopupVisible.notification,
        profile: false,
      };
    } else {
      newfield = {
        notification: false,
        profile: !isPopupVisible.profile,
      };
    }
    setPopupVisible((prev) => {
      prev = newfield;
      return { ...prev };
    });
  };
  return (
    <div className="grid grid-cols-10 h-full ">
      <div className=" col-span-2 ">
        <div
          className="flex justify-center items-center h-full px-[6%] gap-2 cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            store.navTo("/" + URLPRIFIX + "/dashboard");
          }}
        >
          <img
            src="/Assets/images/Logo.png"
            alt="Logo"
            className="max-sm:w-[92px] max-sm:h-[67px] md:w-[42px] md:h-[30px] lg:w-[60px] lg:h-[40px] "
          />
          <h1
            className="font-bold max-sm:text-[28px] md:text-[12px] lg:text-[16px] xl:text-[20px] max-sm:hidden cursor-pointer z-50"
            onClick={() => {}}
          >
            Absolute Legal Law Firm
          </h1>
        </div>
      </div>
      <div className=" col-span-6">
        <div className="flex items-center h-full pl-[5%]  font-semibold max-sm:text-[38px] md:text-[24px] lg:text-[32px]">
          {store.getheader().toUpperCase()}
        </div>
      </div>
      <div className="col-span-2">
        <div className="flex items-center justify-center h-full md:gap-6 lg:gap-8 xl:gap-12 max-sm:hidden relative">
          <img
            src="/Assets/images/notification.png"
            alt="Notification"
            id="notification"
            onClick={(e) => {
              e.preventDefault()
              togglePopup("notification");
              getNotification();
              console.log("On click detected ");
            }}
            className="max-sm:w-[92px] max-sm:h-[67px] md:w-[25px] md:h-[25px] lg:w-[40px] lg:h-[40px] cursor-pointer z-50 hover:scale-110 hover:animate-pulse hover:duration-150 "
          />

          <div
            className={
              "absolute top-full right-6 mt-0 bg-secondary min-w-[320px] max-h-[350px] p-4 shadow-2xl rounded-2xl border border-[#a9a9ff] border-1 transition-all ease-in-out " +
              (isPopupVisible.notification
                ? " z-10 opacity-100 delay-75 translate-y-0 scale-100 duration-700"
                : " -Z-10 opacity-0 -translate-y-32 translate-x-4 scale-50")
            }
          >
            <h1 className={contentHeader}>Notifications</h1>

            <button
              className={
                "absolute right-5 top-4 text-[18px] bg-primary lg:h-6 lg:w-6 h-9 w-9 flex justify-center items-center rounded-full  text-secondary hover:bg-tertiary hover:bg-opacity-45" +
                (isPopupVisible.notification
                  ? " cursor-pointer "
                  : " cursor-default ")
              }
              onClick={() =>
                isPopupVisible.notification && togglePopup("notification")
              }
            >
              &times;
            </button>

            {store.getsystemvalue("Notification") &&
            store.getsystemvalue("Notification").length > 0 ? (
              <div className="overflow-y-auto max-h-[250px]">
                {store.getsystemvalue("Notification").map((item, index) => (
                  <div
                    key={index}
                    className="mb-2 p-1 pt-5 border-b border-gray-300 hover:bg-tertiary hover:bg-opacity-45 relative"
                  >
                    {/* <div className="absolute right-1  hover:bg-[#a9a9ff] hover:bg-opacity-45 ">
                      <IoChevronForwardOutline className="w-6 h-6" />
                    </div> */}
                    <h4 className="text-sm font-bold">{item.title}</h4>
                    <p className="text-sm my-3">{item.body}</p>
                    {/* <p className="text-sm">
                      {`${item.sentAt.split("T")[0]} at ${item.sentAt
                        .split("T")[1]
                        .substring(0, 5)}`}
                    </p> */}
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-600 text-sm">You have no notifications</p>
            )}
          </div>

          <img
            src="/Assets/images/profile.png"
            alt="Logo"
            onClick={() => togglePopup("profile")}
            className="max-sm:w-[92px] max-sm:h-[67px] md:w-[25px] md:h-[25px] lg:w-[40px] lg:h-[40px] cursor-pointer z-50 hover:scale-110"
          />
          <div
            className={
              "absolute top-full right-6 mt-0 bg-secondary min-w-[220px] max-h-[350px] p-4 shadow-2xl rounded-2xl border border-[#a9a9ff] border-1 transition-all ease-in-out " +
              (isPopupVisible.profile
                ? " z-10 opacity-100 delay-75 translate-y-0 scale-100 duration-700 translate-x-0"
                : " -Z-10 opacity-0 -translate-y-24 translate-x-10 scale-50")
            }
          >
            <div>
              <h1 className={contentHeader}>{user.name?.toUpperCase()}</h1>
              <h1 className={"text-[12px]"}>profile</h1>
            </div>
            <button
              className={
                "absolute right-5 top-4 text-[18px] bg-primary lg:h-6 lg:w-6 h-9 w-9 flex justify-center items-center rounded-full  text-secondary hover:bg-tertiary hover:bg-opacity-45" +
                (isPopupVisible.profile
                  ? " cursor-pointer "
                  : " cursor-default ")
              }
              onClick={() => isPopupVisible.profile && togglePopup("profile")}
            >
              &times;
            </button>
            <div className="overflow-y-auto max-h-[250px]">
              <div
                className={
                  "mb-2 p-1 pt-3 border-b border-gray-300 hover:bg-tertiary hover:bg-opacity-45 relative " +
                  (isPopupVisible.profile
                    ? " cursor-pointer "
                    : " cursor-default ")
                }
              >
                <h1
                  onClick={() => {
                    store.navTo("/" + URLPRIFIX + "/users/profile/" + user.id);
                    isPopupVisible.profile && togglePopup("profile");
                  }}
                >
                  My Profile
                </h1>
              </div>
              <div
                className={
                  "mb-2 p-1 pt-3 border-b border-gray-300 hover:bg-tertiary hover:bg-opacity-45 relative " +
                  (isPopupVisible.profile
                    ? " cursor-pointer "
                    : " cursor-default ")
                }
              >
                <h1
                  onClick={() => {
                    store.logout();
                  }}
                >
                  Logout
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div className="items-center justify-center h-full md:gap-6 lg:gap-8 max-sm:flex hidden">
          <div
            className="text-[38px]"
            onClick={(e) => {
              e.preventDefault();
              setsider((prev) => {
                if (prev === "max-sm:hidden") {
                  prev = "max-sm:block";
                } else {
                  prev = "max-sm:hidden";
                }
                return prev;
              });
            }}
          >
            <GiHamburgerMenu />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
