import Dropdown from "../../Components/Fields/Dropdown";
import {
  CheckMandatory,
  ErrorAlert,
  getList,
  GetListDropdown,
  GetRecords,
  saveRecord,
  SuccessAlert,
  UpdateRecord,
  URLPRIFIX,
  WarningAlert,
} from "../../functions/api";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import String from "../../Components/Fields/String";
import { useStore } from "../../Components/StateProvider";
import FormView from "../../Components/Elements/FormView";
import ListView from "../../Components/Elements/ListView";
import DatePicker from "../../Components/Fields/DatePicker";
import Journal from "../../Components/Fields/Journal";
import FileInput from "../../Components/Fields/FileInput";
import Attachement from "../../Components/Fields/Attachement";
import { logDOM } from "@testing-library/react";
import { getlocal } from "../../functions/localstorage";
import { toast } from "react-toastify";
import { PrimaryBTN, secondaryBTN } from "../../Constancs/cssconst";

const DocumentsForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { option, id } = useParams();
  const user = getlocal("system")?.user || {};
  const tablename = "document";
  const setrecord = "/tasks/createtask/11";
  const getrecord = `/tasks/gettask/${id}`;
  const updaterecord = "/tasks/updatetask/" + id+"/11";
  const [GenerateInvoice, setGenerateInvoice] = useState(false);
  const [CompanyNumber, setCompanyNumber] = useState("");
  const [CompanyList, setCompanyList] = useState([
    { label: "Please Choose", value: "" },
  ]);
  const store = useStore();
  const init = {
    project_id: "",
    form_id: "0",
    film_id: "",
    contact_id: "",
    assigned_to: "",
    task_title: "",
    dueDate: "",
    priority: "",
    editor_id: "",
    status: "Draft",
    description: "",
    attachmenturl: [],
    activeStatus: true,
    child: [],
  };

  useEffect(() => {
    // console.log("Params",param);

    store.setmodule(tablename, init).then(() => {
      if (id != undefined) {
        store.setheader(option.toUpperCase() + " " + tablename);

        if (option === "view") {
          store.setprops("status", { hidden: false, readonly: true });
          store.setprops("activeStatus", { hidden: false, readonly: true });
          store.setprops("dueDate", { hidden: false, readonly: true });
          store.setprops("description", { hidden: false, readonly: true });
          store.setprops("priority", { hidden: false, readonly: true });
          // props for the Mandatory field
          store.setprops("project_id", { mandatory: false, readonly: true });
          store.setprops("form_id", { mandatory: false, readonly: true });
          store.setprops("film_id", { mandatory: false, readonly: true });
          store.setprops("contact_id", { mandatory: false, readonly: true });
          store.setprops("assigned_to", { mandatory: false, readonly: true });
          store.setprops("task_title", { mandatory: false, readonly: true });
          store.setprops("dueDate", { mandatory: false, readonly: true });
        } else {
          store.setprops("status", { hidden: false, readonly: false });
          store.setprops("activeStatus", { hidden: false, readonly: false });
          store.setprops("dueDate", { hidden: false, readonly: false });
          store.setprops("description", { hidden: false, readonly: false });
          store.setprops("priority", { hidden: false, readonly: false });
          // props for the Mandatory field
          store.setprops("project_id", { mandatory: false, readonly: true });
          store.setprops("form_id", { mandatory: false, readonly: true });
          store.setprops("film_id", { mandatory: false, readonly: true });
          store.setprops("contact_id", { mandatory: false, readonly: true });
          store.setprops("assigned_to", { mandatory: false, readonly: false });
          store.setprops("task_title", { mandatory: false, readonly: false });
          store.setprops("dueDate", { mandatory: false, readonly: false });
        }

        GetRecords(getrecord).then(async (res) => {
          console.log(tablename + " Data - ", res, res.record);
          if (res.success) {
            init.project_id = res.record.parent[0].project_id;
            init.form_id = res.record.parent[0].form_id;
            init.film_id = res.record.parent[0].film_id;
            init.contact_id = res.record.parent[0].contact_id;
            init.assigned_to = res.record.parent[0].assigned_to;
            init.task_title = res.record.parent[0].task_title;
            init.dueDate = res.record.parent[0].dueDate;
            init.priority = res.record.parent[0].priority;
            init.status = res.record.parent[0].status;
            init.description = res.record.parent[0].description;
            init.editor_id = res.record.parent[0].editor_id;
            init.attachmenturl = res.record.parent[0].attachmenturl || [];
            GetArtistList(res.record.parent[0].contact_id,  res.record.parent[0].film_id);

            // init.activeStatus = res.record.parent[0].activeStatus;
            if (option === "view") {
              init.child = [
                {
                  list: res.record.child,
                  header: [
                    ["Task Title", "subtask_title"],
                    ["Description", "description"],
                    ["Priority", "priority"],
                    ["Due Date", "dueDate"],
                    ["Status", "status"],
                    ["Action", "ACTION"],
                  ],
                  table: "Task",
                },
              ];
            }
            if (
              res.record.parent[0].form_id != 0 &&
              res.record.parent[0].form_id != undefined &&
              res.record.parent[0].film_id != 0 &&
              res.record.parent[0].film_id != undefined
            ) {
              store.setprops("form_id", { readonly: true });
              store.setprops("film_id", { readonly: true });
            } else {
              store.setprops("form_id", { hidden: true });
              store.setprops("film_id", { hidden: true });
            }

            store.reset(init);
          }
        });
      } else {
        const data = store.getrecord("document") || {};
        console.log(
          " autofill check Contack Update1",
          store.getrecord("document"),
          store.getsystemvalue()
        );
        if (
          data.contact_id !== "" &&
          data.contact_id !== undefined &&
          (data.project_id === "" || data.project_id === undefined)
        ) {
          ClientNameChange(data.contact_id);
        } else if (data.project_id !== "" && data.project_id !== undefined) {
          projectNameChange(data.project_id);
        }

        store.setheader("CREATE NEW " + tablename);
        store.setprops("activeStatus", { hidden: true });
        store.setprops("status", { hidden: true });
        store.setvalue("status", "Draft");
        // props for the Mandatory field
        store.setprops(
          "project_id",
          queryParams.get("project_id")
            ? {
                mandatory: queryParams.get("project_id").includes("mandatory"),
                readonly: queryParams.get("project_id").includes("readonly"),
                hidden: queryParams.get("project_id").includes("hidden"),
              }
            : { mandatory: true }
        );
        store.setprops(
          "form_id",
          queryParams.get("form_id")
            ? {
                mandatory: queryParams.get("form_id").includes("mandatory"),
                readonly: queryParams.get("form_id").includes("readonly"),
                hidden: queryParams.get("form_id").includes("hidden"),
              }
            : { hidden: true }
        );
        store.setprops(
          "film_id",
          queryParams.get("film_id")
            ? {
                mandatory: queryParams.get("film_id").includes("mandatory"),
                readonly: queryParams.get("film_id").includes("readonly"),
                hidden: queryParams.get("film_id").includes("hidden"),
              }
            : { hidden: true }
        );
        store.setprops(
          "contact_id",
          queryParams.get("contact_id")
            ? {
                mandatory: queryParams.get("contact_id").includes("mandatory"),
                readonly: queryParams.get("contact_id").includes("readonly"),
                hidden: queryParams.get("contact_id").includes("hidden"),
              }
            : { mandatory: true }
        );
        store.setprops(
          "assigned_to",
          queryParams.get("assigned_to")
            ? {
                mandatory: queryParams.get("assigned_to").includes("mandatory"),
                readonly: queryParams.get("assigned_to").includes("readonly"),
                hidden: queryParams.get("assigned_to").includes("hidden"),
              }
            : { mandatory: true }
        );
        store.setprops(
          "task_title",
          queryParams.get("task_title")
            ? {
                mandatory: queryParams.get("task_title").includes("mandatory"),
                readonly: queryParams.get("task_title").includes("readonly"),
                hidden: queryParams.get("task_title").includes("hidden"),
              }
            : { mandatory: true }
        );
        store.setprops(
          "dueDate",
          queryParams.get("dueDate")
            ? {
                mandatory: queryParams.get("dueDate").includes("mandatory"),
                readonly: queryParams.get("dueDate").includes("readonly"),
                hidden: queryParams.get("dueDate").includes("hidden"),
              }
            : { mandatory: true }
        );
        store.setprops(
          "priority",
          queryParams.get("priority")
            ? {
                mandatory: queryParams.get("priority").includes("mandatory"),
                readonly: queryParams.get("priority").includes("readonly"),
                hidden: queryParams.get("priority").includes("hidden"),
              }
            : { mandatory: false }
        );
        store.setprops(
          "description",
          queryParams.get("description")
            ? {
                mandatory: queryParams.get("description").includes("mandatory"),
                readonly: queryParams.get("description").includes("readonly"),
                hidden: queryParams.get("description").includes("hidden"),
              }
            : { mandatory: false }
        );
      }

      GetListDropdown({
        listUrl: "/users/getusers",
        field: "userName",
        sort: "asc",
        count: 100,
        store: store,
      });
      GetListDropdown({
        listUrl: "/api/v2/Customer/getclients",
        field: "clientname",
        sort: "asc",
        count: 100,
        store: store,
      });
      GetListDropdown({
        listUrl: "/api/FilmMaster/getfilms",
        field: "film_name",
        sort: "asc",
        count: 100,
        store: store,
      });
      GetProjectList();
    });
  }, []);

  async function GetProjectList(client = {}) {
    console.log("GetProjectList client", client);

    await getList({
      listUrl: "/project/getprojectlist/11",
      sort: "asc",
      field: "clientName",
      search: client.clientname,
      count: 10000,
    }).then((projects) => {
      if (projects.success) {
        projects = projects?.data?.list || [];
        projects = projects.map((project) => ({
          value: project.id,
          label: project.projectName,
        }));
        console.log("GetProjectList", projects);
        store.setprops("projectName_list", projects);
      }
    });
  }
  async function GetArtistList(Customer_id, film_id) {
    if (Customer_id != undefined && film_id != undefined) {
      await GetRecords(`/tasks/getartistid/${Customer_id}/${film_id}`).then(
        (Artists) => {
          if (Artists.success) {
            Artists = Artists?.record || [];
            Artists = Artists.map((artist) => ({
              value: artist.artist_id,
              label: artist.artist_name,
            }));
            console.log("name_of_Artist_list", Artists);

            store.setprops("name_of_Artist_list", Artists);
          }
        }
      );
    }
  }

  function update() {
    store.startLoading();
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.id = Number(id);
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }

    GetRecords("/project/getproject/" + data.project_id + "/11").then((res) => {
      if (res.success) {
        let parentendDate = new Date(res.record.parent.endDate || "");
        let parentstartDate = new Date(res.record.parent.startDate || "");
        let childDate = new Date(data.dueDate || "");
        if (parentendDate >= childDate && parentstartDate <= childDate) {
          UpdateRecord(updaterecord, data).then((res) => {
            if (res.success) {
              SuccessAlert("Document Updated Successfully");
              store.stopLoading();
              store.navback();
              store.reset(init);
            }
          });
        } else {
          store.stopLoading();

          if (parentendDate <= childDate) {
            ErrorAlert(
              "Document due date cannot be later than the Project end date!"
            );
          } else if (parentstartDate >= childDate) {
            ErrorAlert(
              "Document due date cannot be earlier than the Project start date!"
            );
          }
        }
      } else {
      }
    });
  }

  function Submit() {
    store.startLoading();
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.createdBy = store.getuser().id;
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }

    console.log("Sumbit", data);
    if (data.project_id === "" || data.project_id === undefined) {
      CheckMandatory(tablename, store);
    } else {
      GetRecords("/project/getproject/" + data.project_id + "/11").then(
        (res) => {
          if (res.success) {
            let parentendDate = new Date(res.record.parent.endDate || "");
            let parentstartDate = new Date(res.record.parent.startDate || "");
            let childDate = new Date(data.dueDate || "");
            if (CheckMandatory(tablename, store)) {
              if (parentendDate >= childDate && parentstartDate <= childDate) {
                saveRecord("/editor/addeditor", {
                  editor_content: "Create Your Doc Hear",
                }).then((res) => {
                  console.log("addeditor", res);
                  if (res.success) {
                    data.editor_id = res.Id.response.id;
                  }
                  store.stopLoading();

                  saveRecord(setrecord, data).then((res) => {
                    console.log("Resonce of saveRecord - ", res);
                    if (res.success) {
                      SuccessAlert("Document Submitted Successfully");
                      store.stopLoading();
                      store.navback();
                      store.reset(init);
                    } else {
                      ErrorAlert(
                        res?.errormessage?.response?.data ||
                          " Error Found Please contact the Admin"
                      );
                      store.stopLoading();
                    }
                  });
                });
                // Change the Project states into inprogress
                UpdateRecord("/forms/updateformstatus/" + data.form_id).then(
                  (res) => {
                    console.log("updateformstatus", res);
                  }
                );
              } else {
                store.stopLoading();

                if (parentendDate <= childDate) {
                  ErrorAlert(
                    "Document due date cannot be later than the Project end date!"
                  );
                } else if (parentstartDate >= childDate) {
                  ErrorAlert(
                    "Document due date cannot be earlier than the Project start date!"
                  );
                }
              }
            }
          } else {
            store.stopLoading();
            ErrorAlert(
              res?.errormessage?.response?.data ||
                " Error Found Please contact the Admin"
            );
          }
        }
      );
    }
  }

  function popupforcompany() {
    return <div>Hi Brows</div>;
  }

  function NavigateToInvoice(company_id) {
    let contact_id = store.getvalue("contact_id");
    let project_id = store.getvalue("project_id");
    GetRecords("/contact/contacts/" + contact_id).then((res) => {
      if (res.success) {
        res = res?.record?.parent || [];
        if (res.length > 0) {
          res = res[0].client_id;
          if (res != null || res != undefined) {
            store.navTo(
              "/" +
                URLPRIFIX +
                "/" +
                company_id +
                "/accounts/invoice/create?proj_id=" +
                project_id +
                "&cust_id=" +
                res +
                "&doc_id=" +
                id
            );
          } else {
            ErrorAlert(
              "No Client Name Found, Please update the contact with proper Client Name"
            );
          }
        }
      }
    });
  }

  function createTask() {
    let data = store.getrecord();
    store.setrecord("task", {
      task_id: id,
      project_id: data.project_id,
      form_id: data.form_id,
      contact_id: data.contact_id,
      subtask_title: "",
      assigned_to: data.assigned_to,
      dueDate: "",
      priority: data.priority,
      status: "Draft",
      description: "",
      hold_note: "",
      rejected_reason: "",
      complete_note: "",
      activeStatus: true,
      attachmenturl: [],
      child: [],
    });
    store.navTo(
      "/demo/tasks/create?project_id=readonly&contact_id=readonly&form_id=" +
        (data.form_id == "0" ? "hidden" : "readonly") +
        "&task_id=readonly"
    );
  }

  function FilmNameChange(film_id) {
    console.log("autofill check FilmNameChange");

    GetRecords("/api/FilmMaster/films/" + film_id).then((res) => {
      if (res.success) {
        res = res?.record?.response[0]?.film_name;
        getList({
          listUrl: "/contact/getcontacts",
          field: "film_name",
          search: res,
          sort: "asc",
          page: 1,
          count: 100,
        }).then((reslist) => {
          console.log("FilmNameChange .list ", reslist);
          if (reslist.success) {
            reslist = reslist?.data.list;
            if (reslist.length === 1) {
              store.setvalue("contact_id", reslist[0].id || "");
              ClientNameChange(reslist[0].id || "");
            } else {
              store.setvalue("contact_id", "");
            }

            let list = [];
            for (let record of reslist) {
              list.push({ value: record.id, label: record.clientName });
              console.log("FilmNameChange .record - ", record);
            }
            store.setprops("clientname_list", list);
          }
        });
      }
    });
  }
  function ClientNameChange(client_id) {
    // console.log("autofill check ClientNameChange");
    let client = store
      .getprops("clientname_list")
      .find((item) => item.value == client_id);

    GetProjectList(client);
    store.setvalue("project_id", "");
    store.setprops("form_id", { hidden: true });
    store.setprops("film_id", { hidden: true });
  }
  function projectNameChange(project_id) {
    GetRecords("/project/getproject/" + project_id + "/11").then((project) => {
      if (project.success) {
        project = project?.record || {};
        console.log("projectNameChange", project);
        if (project.parent.category_id == 1) {
          store.setprops("form_id", { mandatory: true });
          store.setprops("film_id", { mandatory: true, readonly: true });
        } else {
          store.setprops("form_id", { hidden: true });
          store.setprops("film_id", { hidden: true });
          store.setvalue("form_id", "0");
          store.setvalue("film_id", "0");
        }
        store.setvalue("contact_id", project.parent.contact_id);
        store.setvalue("film_id", project.parent.film_id);
        GetArtistList(project.parent.contact_id, project.parent.film_id);
      }
    });
  }
  function FormNameChange(form_id) {
    console.log("autofill check FormNameChange");

    GetRecords("/forms/getforms/" + form_id).then((res) => {
      res = res?.record && res?.record[0].form;
      console.log("FormNameChange", res);
      store.getvalue("contact_id") === "" &&
        store.getstore.setvalue("contact_id", res?.contact_id);
      store.getvalue("contact_id") === "" && ClientNameChange(res?.contact_id);
      store.getvalue("film_id") === "" &&
        store.setvalue("film_id", res?.film_id);
    });
  }

  return (
    <FormView
      actions={[
        {
          Name: "Back",
          onclick: () => {
            store.reset(init);
            // store.navTo("/" + URLPRIFIX + "/documents/list");
            store.navback();
          },
          visible: true,
        },
        {
          Name: "Generate Invoice",
          onclick: () => {
            // api/v2/Customer/getclient/2
            GetRecords(
              "/contact/contacts/" + store.getvalue("contact_id")
            ).then((res) => {
              console.log("Generate Invoice", res);

              if (res.success) {
                res = res?.record?.parent[0] || {};
                res = res.client_id;
                GetRecords("/api/v2/Customer/getclient/" + res).then(
                  (client) => {
                    if (client.success) {
                      client = client?.record || {};
                      client = client?.company_id?.map((item) => ({
                        value: item.company_id,
                        label: item.company_name,
                      }));
                      setCompanyList((prev) => {
                        return [...prev, ...client];
                      });
                    }
                    console.log("Generate Invoice", client);
                  }
                );
              }
            });

            setGenerateInvoice(true);
          },
          type: "primary",
          visible:
            option === "view" &&
            user.role === 1 &&
            store.getvalue("status") === "completed",
        },
        {
          Name: "Submit",
          onclick: Submit,
          type: "primary",
          visible: id === undefined,
        },
        {
          Name: "Update",
          onclick: update,
          type: "primary",
          visible: option === "edit" && store.getprops("update"),
        },
        {
          Name: "Create Task",
          type: "primary",
          onclick: createTask,
          visible: option === "view" && store?.state?.properties?.tasks?.add,
        },
        // {
        //   Name: "Open Editor",
        //   onclick: () => {
        //     store.navtotab(
        //       "/" +
        //         URLPRIFIX +
        //         "/editor/" +
        //         (store.getvalue("editor_id") || "1")
        //     );
        //   },
        //   type: "primary",
        //   visible:
        //     option === "view" && store.getvalue("status") !== "completed",
        // },
      ]}
    >
      {GenerateInvoice && (
        <div className="absolute flex h-[80%] w-[95%] rounded-lg z-50 bg-black bg-opacity-15 backdrop-blur-sm justify-center items-center">
          <div className="flex flex-col items-center bg-secondary px-20 py-5 rounded-lg">
            <h1 className="font-bold">Please confirm the Company</h1>
            <div className="flex flex-col pt-6 pb-10 w-[80%]">
              <label>Company Name</label>
              <select
                className=" flex w-full border rounded-lg"
                onChange={(event) => {
                  setCompanyNumber(event.target.value);
                }}
              >
                {CompanyList.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex justify-evenly w-full">
              <button
                className={secondaryBTN}
                onClick={() => {
                  setGenerateInvoice(false);
                }}
              >
                Back
              </button>
              <button
                className={PrimaryBTN}
                onClick={() => {
                  if (CompanyNumber != "") {
                    NavigateToInvoice(CompanyNumber);
                  } else {
                    ErrorAlert(
                      "Please choose the company for generate invoice."
                    );
                  }
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="grid grid-cols-2 max-md:grid-cols-1 gap-6 w-full ">
        <String name="task_title" label="Document Name :" />
        <Dropdown
          name="contact_id"
          label="Customer :"
          list={store.getprops("clientname_list")}
          onchange={ClientNameChange}
        />
        <Dropdown
          name="project_id"
          label="Project :"
          list={store.getprops("projectName_list") || []}
          onchange={projectNameChange}
          onAdd={() => {
            let data = store.getrecord();
            console.log("autofill check Data", data);

            store.setsystemvalue("module", "project");
            store.setrecord("project", {
              film_id: data.film_id,
              contact_id: data.contact_id,
            });
            store.navTo(
              "/" +
                URLPRIFIX +
                "/projects/create?film_id=readonly&contact_id=readonly"
            );
          }}
          onView={(id) => {
            store.navTo("/" + URLPRIFIX + "/projects/view/" + id);
          }}
        />
        <Dropdown
          name="film_id"
          label="Film :"
          list={store.getprops("film_name_list") || []}
          onchange={FilmNameChange}
        />
        <Dropdown
          name="form_id"
          label="Artist :"
          list={store.getprops("name_of_Artist_list") || []}
          onchange={FormNameChange}
        />
        <Dropdown
          name="assigned_to"
          label="Assigned To :"
          list={store.getprops("userName_list") || []}
        />
        <div className="col-span-2 max-md:col-span-1">
          <Journal
            name="description"
            label="Document Description :"
            doubleline={true}
          />
        </div>
        <DatePicker name="dueDate" label="Due Date :" />
        <Dropdown
          name="priority"
          label="Priority :"
          list={[
            { value: "High", label: "High" },
            { value: "Medium", label: "Medium" },
            { value: "Low", label: "Low" },
          ]}
        />
        <Dropdown
          name="status"
          label="Status :"
          list={[
            { value: "Draft", label: "Draft" },
            { value: "inprogress", label: "inprogress" },
            { value: "completed", label: "Completed" },
            { value: "billed", label: "Billed" },
          ]}
        />
        <Dropdown
          name="activeStatus"
          label="Active Status :"
          list={[
            { value: true, label: "Active" },
            { value: false, label: "InActive" },
          ]}
        />

        <div className="col-span-2  max-md:col-span-1 px-2">
          <div className="font-bold text-[20px]">Attachements</div>
          <div className="grid grid-cols-5">
            {store.getvalue("attachmenturl")?.length > 0 ? (
              store.getvalue("attachmenturl").map((item, index) => {
                // console.log("test 223", item);
                return (
                  <div className="col-span-1 flex border-r pr-6 w-full  items-center">
                    <Attachement name={"attachmenturl"} fileurl={item} />
                    <button
                      className="hover:bg-tertiary p-1 h-fit w-fit rounded-full"
                      onClick={() => {
                        let arr = [];
                        store
                          .getvalue("attachmenturl")
                          .map((attachitem, attachindex) => {
                            if (attachindex !== index) {
                              arr.push(attachitem);
                            }
                          });
                        store.setvalue("attachmenturl", arr);
                      }}
                    >
                      X
                    </button>
                  </div>
                );
              })
            ) : (
              <>No Data</>
            )}
            {option !== "view" && (
              <FileInput
                prefix={"TASK" + id}
                onchange={(file) => {
                  let attachmenturl = store.getvalue("attachmenturl") || [];
                  attachmenturl.push(file);
                  store.setvalue("attachmenturl", attachmenturl);
                }}
              />
            )}
          </div>
        </div>

        <div className="col-span-2  max-md:col-span-1 px-2">
          {store.getvalue("child") &&
            store.getvalue("child").map((child) => (
              <>
                <div className="font-bold text-[20px]">
                  {child.table.toUpperCase()}
                </div>
                <ListView
                  Tablehead={
                    "min-w-[150px] pr-[10px] max-sm:text-[26px] md:text-[20px] lg:text-[16px] xl:text-[20px] font-bold"
                  }
                  Tablerecord="pr-[10px] max-sm:text-[26px] md:text-[20px] lg:text-[12px] xl:text-[16px] cursor-pointer "
                  onview={(index) => {
                    store.navTo("/demo/tasks/view/" + index);
                  }}
                  height={"fit"}
                  header={child.header}
                  records={child.list}
                />
              </>
            ))}
        </div>
      </div>
    </FormView>
  );
};

export default DocumentsForm;
