import Dropdown from "../../Components/Fields/Dropdown";
import {
  CheckMandatory,
  ErrorAlert,
  GetDateTimeFormat,
  getList,
  GetListDropdown,
  GetRecords,
  saveRecord,
  SuccessAlert,
  UpdateRecord,
  URLPRIFIX,
  WarningAlert,
} from "../../functions/api";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import String from "../../Components/Fields/String";
import { useStore } from "../../Components/StateProvider";
import FormView from "../../Components/Elements/FormView";
import ListView from "../../Components/Elements/ListView";
import DatePicker from "../../Components/Fields/DatePicker";
import Journal from "../../Components/Fields/Journal";
import FileInput from "../../Components/Fields/FileInput";
import Attachement from "../../Components/Fields/Attachement";
import { getlocal } from "../../functions/localstorage";
import LogHistory from "../../Components/Elements/LogHistory";

const TasksForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { option, id } = useParams();
  const user = getlocal("system")?.user || {};

  const tablename = "task";
  const setrecord = "/subtask/addsubtasks";
  const getrecord = `/subtask/usersubtasks/${id}`;
  const updaterecord = "/subtask/updatesubtask/" + id ;

  const [State, setState] = useState("comments");

  const store = useStore();
  const init = {
    project_id: "",
    form_id: "0",
    // film_id: "",
    contact_id: "",
    task_id: "",
    subtask_title: "",
    assigned_to: "",
    dueDate: "",
    priority: "",
    status: "",
    description: "",
    hold_note: "",
    rejected_reason: "",
    complete_note: "",
    comments: "",
    activeStatus: true,
    attachmenturl: [],
    child: [],
  };

  useEffect(() => {
    // console.log("Params",param);
    console.log("getlocal()?.user", user);

    store.setmodule(tablename, init);
    if (id != undefined) {
      GetTaskLog();
      store.setheader(option.toUpperCase() + " " + tablename);
      store.setprops("activeStatus", { hidden: false });
      store.setprops("Holdpopup", { hidden: true });
      // store.setprops("holdReason", { hidden: true });
      // store.setprops("complitionNotes", { hidden: true });

      if (option === "view") {
        store.setprops("project_id", { hidden: false, readonly: true });
        store.setprops("form_id", { hidden: false, readonly: true });
        // store.setprops("film_id", { hidden: false, readonly: true });
        store.setprops("contact_id", { hidden: false, readonly: true });
        store.setprops("task_id", { hidden: false, readonly: true });
        store.setprops("subtask_title", { hidden: false, readonly: true });
        store.setprops("assigned_to", { hidden: false, readonly: true });
        store.setprops("dueDate", { hidden: false, readonly: true });
        store.setprops("priority", { hidden: false, readonly: true });
        store.setprops("status", { hidden: false, readonly: true });
        store.setprops("description", { hidden: false, readonly: true });
        store.setprops("activeStatus", { hidden: false, readonly: true });
      } else {
        store.setprops("project_id", { hidden: false, readonly: true });
        store.setprops("form_id", { hidden: false, readonly: true });
        // store.setprops("film_id", { hidden: false, readonly: true });
        store.setprops("contact_id", { hidden: false, readonly: true });
        store.setprops("task_id", { hidden: false, readonly: true });
        store.setprops("subtask_title", { hidden: false, readonly: false });
        store.setprops("assigned_to", { hidden: false, readonly: false });
        store.setprops("dueDate", { hidden: false, readonly: false });
        store.setprops("priority", { hidden: false, readonly: false });
        store.setprops("status", { hidden: false, readonly: false });
        store.setprops("description", { hidden: false, readonly: false });
        store.setprops("activeStatus", { hidden: false, readonly: false });
      }

      GetRecords(getrecord).then(async (res) => {
        console.log(tablename + " Data - ", res, res.record);
        if (res.success) {
          init.project_id = res.record.parent[0].project_id;
          init.form_id = res.record.parent[0].form_id;
          // init.film_id = res.record.parent[0].film_id;
          init.task_id = res.record.parent[0].task_id;
          init.contact_id = res.record.parent[0].contact_id;
          init.subtask_title = res.record.parent[0].subtask_title;
          init.assigned_to = res.record.parent[0].assigned_to;
          init.dueDate = res.record.parent[0].dueDate;
          init.priority = res.record.parent[0].priority;
          init.hold_note = res.record.parent[0].hold_note || "";
          init.rejected_reason = res.record.parent[0].rejected_reason || "";
          init.complete_note = res.record.parent[0].complete_note || "";
          init.status = res.record.parent[0].status;
          init.comments = res.record.parent[0].comments;
          init.description = res.record.parent[0].description;
          init.editor_id = res.record.child[0].editor_id;
          init.activeStatus = res.record.parent[0].activeStatus;
          init.attachmenturl = res.record.parent[0].attachmenturl || [];

          store.reset(init);
          res.record.parent[0].form_id == "0" &&
            store.setprops("form_id", { hidden: true });

          init.hold_note === "" &&
            store.setprops("hold_note", { hidden: true, readonly: true });
          init.complete_note === "" &&
            store.setprops("complete_note", { hidden: true, readonly: true });
          res.record.parent[0].rejected_reason === "" &&
            store.setprops("rejected_reason", { hidden: true, readonly: true });
        }
      });
    } else {
      store.setheader("CREATE NEW " + tablename);
      store.setprops("activeStatus", { hidden: true });
      store.setprops("hold_note", { hidden: true, readonly: true });
      store.setprops("Holdpopup", { hidden: true });
      store.setprops("rejected_reason", { hidden: true, readonly: true });
      store.setprops("complete_note", { hidden: true, readonly: true });
      store.setprops("complitionNotes", { hidden: true });
      store.setprops("status", { hidden: true, readonly: true });
      store.setvalue("status", "Draft");
      // props for the Mandatory field
      store.setprops("subtask_title", { mandatory: true });
      store.setprops("dueDate", { mandatory: true });
      store.setprops("assigned_to", { mandatory: true });

      store.setprops(
        "project_id",
        queryParams.get("project_id")
          ? {
              mandatory: queryParams.get("project_id").includes("mandatory"),
              readonly: queryParams.get("project_id").includes("readonly"),
              hidden: queryParams.get("project_id").includes("hidden"),
            }
          : { mandatory: true }
      );
      store.setprops(
        "form_id",
        queryParams.get("form_id")
          ? {
              mandatory: queryParams.get("form_id").includes("mandatory"),
              readonly: queryParams.get("form_id").includes("readonly"),
              hidden: queryParams.get("form_id").includes("hidden"),
            }
          : { hidden: true }
      );
      // store.setprops(
      //   "film_id",
      //   queryParams.get("film_id")
      //     ? {
      //         mandatory: queryParams.get("film_id").includes("mandatory"),
      //         readonly: queryParams.get("film_id").includes("readonly"),
      //         hidden: queryParams.get("film_id").includes("hidden"),
      //       }
      //     : { mandatory: true }
      // );
      store.setprops(
        "task_id",
        queryParams.get("task_id")
          ? {
              mandatory: queryParams.get("task_id").includes("mandatory"),
              readonly: queryParams.get("task_id").includes("readonly"),
              hidden: queryParams.get("task_id").includes("hidden"),
            }
          : { mandatory: true }
      );
      store.setprops(
        "contact_id",
        queryParams.get("contact_id")
          ? {
              mandatory: queryParams.get("contact_id").includes("mandatory"),
              readonly: queryParams.get("contact_id").includes("readonly"),
              hidden: queryParams.get("contact_id").includes("hidden"),
            }
          : { mandatory: true }
      );
      store.setprops(
        "priority",
        queryParams.get("priority")
          ? {
              mandatory: queryParams.get("priority").includes("mandatory"),
              readonly: queryParams.get("priority").includes("readonly"),
              hidden: queryParams.get("priority").includes("hidden"),
            }
          : { mandatory: false }
      );
      store.setprops(
        "description",
        queryParams.get("description")
          ? {
              mandatory: queryParams.get("description").includes("mandatory"),
              readonly: queryParams.get("description").includes("readonly"),
              hidden: queryParams.get("description").includes("hidden"),
            }
          : { mandatory: false }
      );
    }
    GetProjectList();
    console.log("projectNameChange", store.getrecord());

    if (store.getvalue("project_id") !== "") {
      getDocumentList(store.getvalue("project_id"));
    } else {
      store.setprops("task_title_list", []);
    }
    GetListDropdown({
      listUrl: "/users/getusers",
      field: "userName",
      sort: "asc",
      count: 100,
      store: store,
    });
    GetListDropdown({
      listUrl: "/forms/getformslist",
      field: "name_of_Artist",
      sort: "asc",
      count: 100,
      store: store,
    });
    GetListDropdown({
      listUrl: "/api/v2/Customer/getclients",
      field: "clientname",
      sort: "asc",
      count: 100,
      store: store,
    });
  }, [option, id]);
  async function GetTaskLog(field = "comments") {
    setState(field);
    // Log input format
    // [
    //   {
    //     User: "Sam",
    //     Action: "Created",
    //     Field: "Task",
    //     date: "25-12-2025",
    //     time: "12:15",
    //     old_data: "",
    //     new_data: "",
    //   },
    // ];

    // check for the Comments Field or not
    if (field == "comments") {
      // load Log form the Backend
      await GetRecords(`/logs/updatelogs/35/${id}`).then((Logs) => {
        if (Logs.success) {
          Logs = Logs?.record || [];
          let OutputLog = [];

          Logs.map((log) => {
            let date = GetDateTimeFormat(new Date(log.modified_date));
            if (log.old_value != null && log.new_value != null) {
              let oldrecord = JSON.parse(log.old_value);
              let newrecord = JSON.parse(log.new_value);

              Object.keys(oldrecord).map((record) => {
                // check for the comment field
                if (record == "comments") {
                  // check for the difference in the field and create a log for the field
                  if (oldrecord[record] != newrecord[record]) {
                    OutputLog.push({
                      User: log?.modified_by,
                      Action: log?.operation_type?.toLowerCase(),
                      Field: record,
                      date: date?.Date,
                      time: date?.Time,
                      old_data: oldrecord[record] + "",
                      new_data: newrecord[record] + "",
                    });
                  }
                }
              });
            }
          });

          // save the Logs(History) to the LogData Field
          store.setprops("LogData", OutputLog);
        } else {
          store.setprops("LogData", []);
        }
      });
    } else {
      await GetRecords(`/logs/updatelogs/35/${id}`).then((Logs) => {
        if (Logs.success) {
          Logs = Logs?.record || [];
          let OutputLog = [];
          Logs.map((log) => {
            let date = GetDateTimeFormat(new Date(log.modified_date));
            if (log.old_value != null && log.new_value != null) {
              let oldrecord = JSON.parse(log.old_value);
              let newrecord = JSON.parse(log.new_value);
              Object.keys(oldrecord).map((record) => {
                if (!["ModifiedDate", "attachmenturl"].includes(record)) {
                  if (oldrecord[record] != newrecord[record]) {
                    console.log(
                      "GetTaskLog ",
                      record,
                      oldrecord[record],
                      newrecord[record],
                      oldrecord[record] == newrecord[record]
                    );
                    OutputLog.push({
                      User: log?.modified_by,
                      Action: log?.operation_type?.toLowerCase(),
                      Field: record,
                      date: date?.Date,
                      time: date?.Time,
                      old_data: oldrecord[record] + "",
                      new_data: newrecord[record] + "",
                    });
                  }
                }
              });
            } else {
              if (log?.operation_type?.toLowerCase() == "created") {
                OutputLog.push({
                  User: log?.modified_by,
                  Action: log?.operation_type?.toLowerCase(),
                  Field: "TASK",
                  date: date?.Date,
                  time: date?.Time,
                  old_data: null,
                  new_data: null,
                });
              }
            }
          });

          store.setprops("LogData", OutputLog);
        } else {
          store.setprops("LogData", []);
        }
      });
    }
  }
  async function GetProjectList(client = {}) {
    console.log("GetProjectList client", client);

    await getList({
      listUrl: "/project/getprojectlist/11",
      sort: "asc",
      field: "clientName",
      search: client.clientname,
      count: 10000,
    }).then((projects) => {
      if (projects.success) {
        projects = projects?.data?.list || [];
        projects = projects.map((project) => ({
          ...project,
          value: project.id,
          label: project.projectName,
        }));
        store.setprops("projectName_list", projects);
      }
    });
  }
  async function getDocumentList(project_id = "") {
    await getList({
      listUrl: "/tasks/getprojectstasklist/11",
      field: "project_id",
      search: project_id,
      count: 10000,
    }).then((task) => {
      if (task.success) {
        task = task?.data?.list || [];
        if (store.getvalue("task_id") === "") {
          if (task.length === 1) {
            store.setvalue("task_id", task[0].id || "");
          } else {
            store.setvalue("task_id", "");
          }
        }

        task = task.map((taskeach) => ({
          ...taskeach,
          value: taskeach.id,
          label: taskeach.task_title,
        }));

        store.setprops("task_title_list", task);
      }
    });
  }
  async function GetArtistList(Customer_id, film_id) {
    if (Customer_id != undefined && film_id != undefined) {
      await GetRecords(`/tasks/getartistid/${Customer_id}/${film_id}`).then(
        (Artists) => {
          if (Artists.success) {
            Artists = Artists?.record || [];
            Artists = Artists.map((artist) => ({
              value: artist.artist_id,
              label: artist.artist_name,
            }));
            console.log("name_of_Artist_list", Artists);

            store.setprops("name_of_Artist_list", Artists);
          }
        }
      );
    }
  }

  function update() {
    store.startLoading();
    let data = store.getrecord();

    data.modifiedBy = store.getuser().id;
    data.id = Number(id);
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    GetRecords("/tasks/gettask/" + data.task_id).then((res) => {
      console.log("gettask ", res);

      if (res.success) {
        let parentDate = new Date(res.record.parent[0].dueDate || "");
        let childDate = new Date(data.dueDate || "");
        if (parentDate >= childDate) {
          UpdateRecord(updaterecord, data).then((res) => {
            if (res.success) {
              store.stopLoading();
              SuccessAlert("Task Updated Successfully");
              // store.navback();
              // store.reset(init);
            }
          });
        } else {
          store.stopLoading();
          WarningAlert(
            "Task due date cannot be later than the document due date!"
          );
        }
      }
    });
  }
  function save() {
    store.startLoading();
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.id = Number(id);
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    GetRecords("/tasks/gettask/" + data.task_id).then((res) => {
      console.log("gettask ", res);

      if (res.success) {
        let parentDate = new Date(res.record.parent[0].dueDate || "");
        let childDate = new Date(data.dueDate || "");
        if (parentDate >= childDate) {
          UpdateRecord(updaterecord, data).then((res) => {
            if (res.success) {
              store.stopLoading();
              SuccessAlert("Comments Save Successfully");
              GetTaskLog();
            }
          });
        } else {
          store.stopLoading();
          WarningAlert(
            "Task due date cannot be later than the document due date!"
          );
        }
      } else {
        store.stopLoading();
        ErrorAlert(
          res?.errormessage?.response?.data ||
            " Error Found Please contact the Admin"
        );
      }
    });
  }
  function inprogress() {
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.status = "inprogress";
    data.id = Number(id);
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    UpdateRecord(updaterecord, data).then((res) => {
      if (res.success) {
        SuccessAlert("States Update to - " + res.Id.response.status);
        console.log("States Update to - ", res);
        store.setvalue("status", "inprogress");
        store.setvalue("hold_note", "");
        store.setprops("hold_note", { hidden: true, readonly: true });
        GetTaskLog();

        // store.navback();
        // store.reset(init);
      }
    });
  }
  function hold() {
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.id = Number(id);
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    if (CheckMandatory(tablename, store)) {
      data.status = "hold";
      UpdateRecord(updaterecord, data).then((res) => {
        if (res.success) {
          store.setvalue("status", "hold");
          SuccessAlert("States Update to - " + res.Id.response.status);
          store.setprops("hold_note", { hidden: false, readonly: true });
          store.setprops("Holdpopup", { hidden: true });
          GetTaskLog();
        }
      });
    }
  }
  function complete() {
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.id = Number(id);
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    if (CheckMandatory(tablename, store)) {
      data.status = "waitingforapproval";
      UpdateRecord(updaterecord, data).then((res) => {
        if (res.success) {
          store.setvalue("status", "waitingforapproval");
          SuccessAlert("States Update to - " + res.Id.response.status);
          store.setprops("complete_note", {
            hidden: false,
            readonly: true,
          });
          store.setprops("completePopup", { hidden: true });
          GetTaskLog();
        }
      });
    }
  }
  function rejected() {
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.id = Number(id);
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    if (CheckMandatory(tablename, store)) {
      data.status = "rejected";
      UpdateRecord(updaterecord, data).then((res) => {
        if (res.success) {
          store.setvalue("status", "rejected");
          SuccessAlert("States Update to - " + res.Id.response.status);
          store.setprops("rejected_reason", { hidden: false, readonly: true });
          store.setprops("rejectpopup", { hidden: true });
          GetTaskLog();
        }
      });
    }
  }
  function approved() {
    let data = store.getrecord();
    console.log("Data", data);

    UpdateRecord(
      "/subtask/updatestatus/" +
        data.task_id +
        "/" +
        id +
        "/" +
        data.project_id +
        "/11/" +
        store.getuser().id
    ).then((res) => {
      console.log("updaterecord", res);

      if (res.success) {
        store.setvalue("status", "completed");
        SuccessAlert("States Update to - " + res.Id.status);
        GetTaskLog();
      }
    });
  }
  function Submit() {
    store.startLoading();
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.createdBy = store.getuser().id;
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    if (CheckMandatory(tablename, store)) {
      GetRecords("/tasks/gettask/" + data.task_id).then((res) => {
        if (res.success) {
          let parentDate = new Date(res.record.parent[0].dueDate || "");
          let childDate = new Date(data.dueDate || "");
          if (parentDate >= childDate) {
            saveRecord(setrecord, data).then((res) => {
              console.log("Responce of saveRecord - ", res);
              if (res.success) {
                store.stopLoading();
                SuccessAlert("Task Submitted Successfully");
                store.navTo("/" + URLPRIFIX + "/tasks/view/" + res.Id.id, {
                  replace: true,
                });
                store.reset(init);
              } else {
                ErrorAlert(
                  res?.errormessage?.response?.data ||
                    " Error Found Please contact the Admin"
                );
                store.stopLoading();
              }
            });
          } else {
            store.stopLoading();
            ErrorAlert(
              "Task due date cannot be later than the document due date!"
            );
          }
        } else {
          ErrorAlert(
            res?.errormessage?.response?.data ||
              " Error Found Please contact the Admin"
          );
          store.stopLoading();
        }
      });
    }
    store.stopLoading();
  }

  function projectNameChange(project_id) {
    if (project_id != "") {
      GetRecords("/project/getproject/" + project_id + "/11").then(
        (project) => {
          if (project.success) {
            project = project?.record || {};
            console.log("projectNameChange", project);
            if (project.parent.category_id == 1) {
              store.setprops("form_id", { mandatory: true, readonly: true });
            } else {
              store.setprops("form_id", { hidden: true });
            }
            store.setvalue("contact_id", project.parent.contact_id);
            store.setvalue("form_id", "");
            GetArtistList(project.parent.contact_id, project.parent.film_id);
            getDocumentList(project.parent.id);
          }
        }
      );
    } else {
      store.setprops("form_id", { hidden: true });
    }
  }
  function ClientNameChange(client_id) {
    let client = store
      .getprops("clientname_list")
      .find((item) => item.value == client_id);

    GetProjectList(client);

    store.setvalue("project_id", "");
    store.setvalue("task_id", "");
    store.setprops("task_title_list", []);
    store.setprops("form_id", { hidden: true });
    store.setprops("film_id", { hidden: true });
  }
  function DocumentChange(task_id) {
    console.log("FormNameChange document");
    let document = store
      .getprops("task_title_list")
      .find((task) => task.id == task_id);

    store.setvalue("form_id", document.form_id);
    store.setvalue("assigned_to", document.assigned_to);
  }
  function FormNameChange(form_id) {
    GetRecords("/forms/getforms/" + form_id).then((res) => {
      console.log("FormNameChange", form_id, res);
      res = res?.record && res?.record[0].form;
      store.getvalue("contact_id") === "" && ClientNameChange(res?.contact_id);
      store.getvalue("contact_id") === "" &&
        store.setvalue("contact_id", res?.contact_id);
      // store.getvalue("film_id") === "" &&
      //   store.setvalue("film_id", res?.film_id);

      getList({
        listUrl: "/tasks/getprojectstasklist",
        field: "form_id",
        search: form_id,
        sort: "asc",
        page: 1,
        count: 100,
      }).then((task) => {
        if (task.success) {
          task = task?.data?.list || [];
          if (task.length === 1) {
            store.setvalue("task_id", task[0].id || "");
          }

          let list = [];
          for (let taskeach of task) {
            list.push({
              value: taskeach.id,
              label: taskeach.task_title,
            });
          }
          store.setprops("task_title_list", list);
          console.log("FormNameChange task_title_list", list);
        }
      });
    });
  }

  return (
    <FormView
      actions={[
        {
          Name: "Back",
          onclick: () => {
            // user.role === 1
            //   ? store.navTo("/" + URLPRIFIX + "/tasks/list")
            //   : store.navTo(
            //       "/" +
            //         URLPRIFIX +
            //         "/tasks/list?field=assigned_to_username&search=" +
            //         user.name
            //     );
            store.navback();
            store.reset(init);
          },
          visible: true,
        },
        {
          Name: "Submit",
          onclick: Submit,
          type: "primary",
          visible: id === undefined,
        },
        {
          Name: "Update",
          onclick: update,
          type: "primary",
          visible: option === "edit" && store.getprops("update"),
        },
        {
          Name: "Start",
          onclick: inprogress,
          type: "primary",
          visible:
            option === "view" &&
            (store.getvalue("status") === "Draft" ||
              store.getvalue("status") === "hold" ||
              store.getvalue("status") === "rejected"),
        },
        {
          Name: "hold",
          onclick: () => {
            store.setprops("hold_note", {
              hidden: false,
              readonly: false,
              mandatory: true,
            });
            store.setprops("Holdpopup", { hidden: false });
          },
          visible:
            option === "view" && store.getvalue("status") === "inprogress",
        },

        {
          Name: "Reject",
          onclick: () => {
            store.setprops("rejected_reason", {
              hidden: false,
              readonly: false,
              mandatory: true,
            });
            store.setprops("rejectpopup", { hidden: false });
          },
          visible:
            option === "view" &&
            store.getvalue("status") === "waitingforapproval" &&
            user?.role == "1",
        },
        {
          Name: "Approve",
          onclick: () => {
            approved();
          },
          type: "primary",
          visible:
            option === "view" &&
            store.getvalue("status") === "waitingforapproval" &&
            user?.role == "1",
        },
        {
          Name: "completed",
          onclick: () => {
            store.setprops("complete_note", {
              hidden: false,
              readonly: false,
              mandatory: true,
            });
            store.setprops("completePopup", { hidden: false });
          },
          type: "primary",
          visible:
            option === "view" && store.getvalue("status") === "inprogress",
        },
        {
          Name: "Sent Remainder",
          onclick: () => {
            store.setprops("assigned_to", {
              hidden: false,
              readonly: false,
              mandatory: true,
            });
            store.setprops("NotificationPopup", { hidden: false });
          },
          // type: "primary",
          visible:
            option === "view" && store.getvalue("status") === "completed",
        },
        // {
        //   Name: "Open Editor",
        //   onclick: () => {
        //     store.navtotab(
        //       "/" +
        //         URLPRIFIX +
        //         "/editor/" +
        //         (store.getvalue("editor_id") || "1")
        //     );
        //   },
        //   type: "primary",
        //   visible:
        //     option === "view" &&
        //     store.getvalue("status") !== "Draft" &&
        //     store.getvalue("status") !== "waitingforapproval" &&
        //     store.getvalue("status") !== "completed" &&
        //     store.getvalue("status") !== "rejected",
        // },
      ]}
    >
      {store.getprops("rejectpopup") &&
        store.getprops("rejectpopup").hidden === false && (
          <div className="absolute top-0 left-0 flex h-full w-full justify-center items-center bg-black bg-opacity-35 z-30 rounded-2xl">
            <div className="bg-secondary p-9 w-[50%] rounded-2xl">
              <Journal
                name="rejected_reason"
                label="Reject Reason :"
                doubleline={true}
              />
              <div className="flex justify-end items-center px-[2%] pt-[2%] gap-5">
                <button
                  className="bg-primary text-secondary py-3 px-10 rounded-xl font-bold"
                  onClick={rejected}
                >
                  Ok
                </button>
                <button
                  className="bg-tertiary text-primary py-3 px-10 rounded-xl font-bold"
                  onClick={() => {
                    store.setprops("rejected_reason", {
                      hidden: true,
                      readonly: false,
                    });
                    store.setprops("rejectpopup", { hidden: true });
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        )}
      {store.getprops("Holdpopup") &&
        store.getprops("Holdpopup").hidden === false && (
          <div className="absolute top-0 left-0 flex h-full w-full justify-center items-center bg-black bg-opacity-35 z-30 rounded-2xl">
            <div className="bg-secondary p-9 w-[50%] rounded-2xl">
              <Journal
                name="hold_note"
                label="Hold Reason :"
                doubleline={true}
              />
              <div className="flex justify-end items-center px-[2%] pt-[2%] gap-5">
                <button
                  className="bg-primary text-secondary py-3 px-10 rounded-xl font-bold"
                  onClick={hold}
                >
                  Ok
                </button>
                <button
                  className="bg-tertiary text-primary py-3 px-10 rounded-xl font-bold"
                  onClick={() => {
                    store.setprops("hold_note", {
                      hidden: true,
                      readonly: false,
                    });
                    store.setprops("Holdpopup", { hidden: true });
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        )}
      {store.getprops("completePopup") &&
        store.getprops("completePopup").hidden === false && (
          <div className="absolute top-0 left-0 flex h-full w-full justify-center items-center bg-black bg-opacity-35 z-30 rounded-2xl">
            <div className="bg-secondary p-9 w-[50%] rounded-2xl">
              <Journal
                name="complete_note"
                label="Completion Notes :"
                doubleline={true}
              />
              <div className="flex justify-end items-center px-[2%] pt-[2%] gap-5">
                <button
                  className="bg-primary text-secondary py-3 px-10 rounded-xl font-bold"
                  onClick={complete}
                >
                  Ok
                </button>
                <button
                  className="bg-tertiary text-primary py-3 px-10 rounded-xl font-bold"
                  onClick={() => {
                    store.setprops("complete_note", {
                      hidden: true,
                      readonly: false,
                    });
                    store.setprops("completePopup", { hidden: true });
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        )}
      {store.getprops("NotificationPopup") &&
        store.getprops("NotificationPopup").hidden === false && (
          <div className="absolute top-0 left-0 flex h-full w-full justify-center items-center bg-black bg-opacity-35 z-30 rounded-2xl">
            <div className="bg-secondary p-9 w-[50%] rounded-2xl">
              <Dropdown
                name="assigned_to"
                label="Assigned To :"
                list={store.getprops("userName_list") || []}
              />
              <div className="flex justify-end items-center px-[2%] pt-[2%] gap-5">
                <button
                  className="bg-primary text-secondary py-3 px-10 rounded-xl font-bold"
                  onClick={() => {
                    saveRecord(
                      "/api/Notification/sendnotificationtoanyuser/" +
                        store.getvalue("assigned_to") +
                        '?body=Gentle Remainder for the task -"' +
                        store.getvalue("subtask_title") +
                        '"'
                    ).then((res) => {
                      if (res.success) {
                        SuccessAlert(res.Id);
                      } else {
                        ErrorAlert("Unable to sent Remainder");
                      }
                      store.setprops("assigned_to", {
                        hidden: true,
                        readonly: false,
                        mandatory: false,
                      });
                      store.setprops("NotificationPopup", { hidden: true });
                    });
                  }}
                >
                  Ok
                </button>
                <button
                  className="bg-tertiary text-primary py-3 px-10 rounded-xl font-bold"
                  onClick={() => {
                    store.setprops("assigned_to", {
                      readonly: true,
                    });
                    store.setprops("NotificationPopup", { hidden: true });
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        )}
      <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
        <String name="subtask_title" label="Task Title :" />
        {/* <Dropdown
          name="film_id"
          label="Film Name :"
          list={store.getprops("film_name_list") || []}
          onchange={FilmNameChange}
        /> */}
        <Dropdown
          name="contact_id"
          label="Customer :"
          list={store.getprops("clientname_list")}
          onchange={ClientNameChange}
        />
        <Dropdown
          name="project_id"
          label="Project :"
          list={store.getprops("projectName_list") || []}
          onchange={projectNameChange}
          onView={(id) => {
            store.navTo("/" + URLPRIFIX + "/projects/view/" + id);
          }}
        />

        <Dropdown
          name="task_id"
          label="Document :"
          list={store.getprops("task_title_list") || []}
          onchange={DocumentChange}
          onView={(id) => {
            store.navTo("/" + URLPRIFIX + "/documents/view/" + id);
          }}
        />
        <Dropdown
          name="form_id"
          label="Artist :"
          list={store.getprops("name_of_Artist_list") || []}
          onchange={FormNameChange}
          onView={(id) => {
            store.navTo("/" + URLPRIFIX + "/forms/view/" + id);
          }}
        />
        <Dropdown
          name="assigned_to"
          label="Assigned To :"
          list={store.getprops("userName_list") || []}
        />
        <Dropdown
          name="status"
          label="Status :"
          list={[
            { value: "Draft", label: "Draft" },
            { value: "inprogress", label: "inprogress" },
            { value: "hold", label: "Hold" },
            { value: "waitingforapproval", label: "Waiting For Approval" },
            { value: "approved", label: "Approved" },
            { value: "rejected", label: "Rejected" },
            { value: "completed", label: "Completed" },
            { value: "closedcompleted", label: "Closed Completed" },
          ]}
        />

        <div className="col-span-2 max-md:col-span-1">
          <Journal
            name="description"
            label="Document Description :"
            doubleline={true}
          />
        </div>
        <DatePicker name="dueDate" label="Due Date :" />
        <Dropdown
          name="priority"
          label="Priority :"
          list={[
            { value: "High", label: "High" },
            { value: "Medium", label: "Medium" },
            { value: "Low", label: "Low" },
          ]}
        />
        <Dropdown
          name="activeStatus"
          label="Active Status :"
          list={[
            { value: true, label: "Active" },
            { value: false, label: "InActive" },
          ]}
        />
        <div className="col-span-2  max-md:col-span-1 px-2 ">
          <div className="font-bold text-[20px]">Attachements</div>
          <div className="grid grid-cols-5">
            {store.getvalue("attachmenturl")?.length > 0 ? (
              store.getvalue("attachmenturl").map((item, index) => {
                // console.log("test 223", item);
                return (
                  <div className="col-span-1 flex border-r pr-6 w-full  items-center">
                    <Attachement name={"attachmenturl"} fileurl={item} />
                    <button
                      className="hover:bg-tertiary p-1 h-fit w-fit rounded-full"
                      onClick={() => {
                        let arr = [];
                        store
                          .getvalue("attachmenturl")
                          .map((attachitem, attachindex) => {
                            if (attachindex !== index) {
                              arr.push(attachitem);
                            }
                          });
                        store.setvalue("attachmenturl", arr);
                      }}
                    >
                      X
                    </button>
                  </div>
                );
              })
            ) : (
              <>No Data</>
            )}
            {option !== "view" && (
              <FileInput
                prefix={"TASK" + id}
                onchange={(file) => {
                  let attachmenturl = store.getvalue("attachmenturl") || [];
                  attachmenturl.push(file);
                  store.setvalue("attachmenturl", attachmenturl);
                }}
              />
            )}
          </div>
        </div>
        <div className="col-span-2 max-md:col-span-1 relative">
          <Journal name="comments" label="Comments :" doubleline={true} />
          {option === "view" && (
            <button
              className="absolute right-5 -bottom-8 bg-primary text-secondary px-2 py-1 rounded-lg "
              onClick={() => {
                if (store.getvalue("comments") === "") {
                  WarningAlert(
                    "Please enter a comment. Comments cannot be left empty."
                  );
                } else {
                  save();
                }
              }}
            >
              save
            </button>
          )}
        </div>
        <Journal name="hold_note" label="Hold Reason :" doubleline={true} />
        <Journal
          name="complete_note"
          label="Completion Notes :"
          doubleline={true}
        />
        <Journal
          name="rejected_reason"
          label="Rejection Notes :"
          doubleline={true}
        />
        <LogHistory
          LogArray={store.getprops("LogData")}
          loadData={GetTaskLog}
          logType={State}
        />
      </div>
    </FormView>
  );
};

export default TasksForm;
