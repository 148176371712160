import {
  CheckMandatory,
  ErrorAlert,
  getList,
  GetListDropdown,
  GetRecords,
  saveRecord,
  SuccessAlert,
  UpdateRecord,
  URLPRIFIX,
  WarningAlert,
} from "../../../functions/api";
import React, { useEffect, useState } from "react";
import { resolvePath, useLocation, useParams } from "react-router-dom";
import String from "../../../Components/Fields/String";
import { useStore } from "../../../Components/StateProvider";
import FormView from "../../../Components/Elements/FormView";
import ListView from "../../../Components/Elements/ListView";
import DatePicker from "../../../Components/Fields/DatePicker";
import Journal from "../../../Components/Fields/Journal";
import { getlocal } from "../../../functions/localstorage";
import Attachement from "../../../Components/Fields/Attachement";
import FileInput from "../../../Components/Fields/FileInput";
import { PrimaryBTN, secondaryBTN } from "../../../Constancs/cssconst";
import Dropdown from "../../../Components/Fields/Dropdown";

const TProjectForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { option, id } = useParams();
  const user = getlocal("system")?.user || {};
  const tablename = "project";
  const getrecord = `/project/getproject/${id}/13`;
  const updaterecord = `/project/updateproject/${id}/13`;
  const setrecord = "/project/createproject/13";

  const store = useStore();
  const init = {
    projectName: "",
    projectDescription: "",
     contact_id: "",
    startDate: "",
    endDate: "",
    priority: "",
    status: "Draft",
    activeStatus: true,
    attachmenturl: [],
  };

  useEffect(() => {
    console.log("queryParams", queryParams.get("projectName") === null);
    store
      .setmodule(tablename, init)
      .then((res) => console.log("autofill check", res));
    if (id != undefined) {
      store.setheader(option.toUpperCase() + " " + tablename);
      store.setprops("activeStatus", { hidden: false });
      store.setprops("category_id", { hidden: false, readonly: true }); 

      // props for the Mandatory field
      store.setprops(
        "projectName",
        queryParams.get("projectName")
          ? {
              mandatory: queryParams.get("projectName").includes("mandatory"),
              readonly: queryParams.get("projectName").includes("readonly"),
              hidden: queryParams.get("projectName").includes("hidden"),
            }
          : { mandatory: false }
      );
      store.setprops(
        "projectDescription",
        queryParams.get("projectDescription")
          ? {
              mandatory: queryParams.get("projectDescription").includes("mandatory"),
              readonly: queryParams.get("projectDescription").includes("readonly"),
              hidden: queryParams.get("projectDescription").includes("hidden"),
            }
          : { mandatory: false }
      );
    
      store.setprops(
        "contact_id",
        queryParams.get("contact_id")
          ? {
              mandatory: queryParams.get("contact_id").includes("mandatory"),
              readonly: queryParams.get("contact_id").includes("readonly"),
              hidden: queryParams.get("contact_id").includes("hidden"),
            }
          : { mandatory: false }
      );
      store.setprops(
        "startDate",
        queryParams.get("startDate")
          ? {
              mandatory: queryParams.get("startDate")?.includes("mandatory"),
              readonly: queryParams.get("startDate")?.includes("readonly"),
              hidden: queryParams.get("startDate")?.includes("hidden"),
            }
          : { mandatory: false }
      );
      store.setprops(
        "endDate",
        queryParams.get("endDate")
          ? {
              mandatory: queryParams.get("endDate")?.includes("mandatory"),
              readonly: queryParams.get("endDate")?.includes("readonly"),
              hidden: queryParams.get("endDate")?.includes("hidden"),
            }
          : { mandatory: false }
      );

      store.setprops(
        "priority",
        queryParams.get("priority")
          ? {
              mandatory: queryParams.get("priority")?.includes("mandatory"),
              readonly: queryParams.get("priority")?.includes("readonly"),
              hidden: queryParams.get("priority")?.includes("hidden"),
            }
          : { mandatory: false }
      );

      GetRecords(getrecord).then(async (res) => {
        console.log(tablename + " Data - ", res, res.record);
        if (res.success) {
          let records = {
            ...init,
            projectName: res.record.parent.projectName,
            contact_id: res.record.parent.contact_id,
            projectDescription: res.record.parent.projectDescription,
            startDate: res.record.parent.startDate,
            endDate: res.record.parent.endDate,
            priority: res.record.parent.priority,
            status: res.record.parent.status,
            activeStatus: res.record.parent.activeStatus,
            attachmenturl: res.record.parent.attachmenturl || [],
          };

          if (option === "view") {
            store.setprops("projectName", { hidden: false, readonly: true });
            store.setprops("contact_id", { hidden: false, readonly: true });
            store.setprops("projectDescription", {
              hidden: false,
              readonly: true,
            });
            store.setprops("startDate", { hidden: false, readonly: true });
            store.setprops("endDate", { hidden: false, readonly: true });
            store.setprops("priority", { hidden: false, readonly: true });
            store.setprops("status", { hidden: false, readonly: true });
            store.setprops("activeStatus", { hidden: false, readonly: true });
          
          } else if (option === "edit") {
            store.setprops("status", { hidden: false, readonly: false });
          }
          console.log(tablename + " Data - ", records);

          store.reset(records);
        }
      });
    } else {
      store.setheader("CREATE NEW " + tablename);
      store.setprops("activeStatus", { hidden: true });
      store.setprops("status", { hidden: true });
     
      // store.setvalue("status", "Draft");
      // props for the Mandatory field
      store.setprops(
        "projectName",
        queryParams.get("projectName")
          ? {
              mandatory: queryParams.get("projectName").includes("mandatory"),
              readonly: queryParams.get("projectName").includes("readonly"),
              hidden: queryParams.get("projectName").includes("hidden"),
            }
          : { mandatory: true }
      );
      store.setprops(
        "contact_id",
        queryParams.get("contact_id")
          ? {
              mandatory: queryParams.get("contact_id").includes("mandatory"),
              readonly: queryParams.get("contact_id").includes("readonly"),
              hidden: queryParams.get("contact_id").includes("hidden"),
            }
          : { mandatory: true }
      );
      store.setprops(
        "startDate",
        queryParams.get("startDate")
          ? {
              mandatory: queryParams.get("startDate")?.includes("mandatory"),
              readonly: queryParams.get("startDate")?.includes("readonly"),
              hidden: queryParams.get("startDate")?.includes("hidden"),
            }
          : { mandatory: true }
      );
      store.setprops(
        "endDate",
        queryParams.get("endDate")
          ? {
              mandatory: queryParams.get("endDate")?.includes("mandatory"),
              readonly: queryParams.get("endDate")?.includes("readonly"),
              hidden: queryParams.get("endDate")?.includes("hidden"),
            }
          : { mandatory: true }
      );

      store.setprops(
        "priority",
        queryParams.get("priority")
          ? {
              mandatory: queryParams.get("priority")?.includes("mandatory"),
              readonly: queryParams.get("priority")?.includes("readonly"),
              hidden: queryParams.get("priority")?.includes("hidden"),
            }
          : { mandatory: false }
      );
      store.setprops(
        "projectDescription",
        queryParams.get("projectDescription")
          ? {
              mandatory: queryParams
                .get("projectDescription")
                ?.includes("mandatory"),
              readonly: queryParams
                .get("projectDescription")
                ?.includes("readonly"),
              hidden: queryParams.get("projectDescription")?.includes("hidden"),
            }
          : { mandatory: false, readonly: false }
      );
    }

    GetListDropdown({
      listUrl: "/api/v2/Customer/getclients",
      field: "clientname",
      sort: "asc",
      count: 100,
      store: store,
    });
  }, [option, id]);

  function update() {
    store.startLoading();
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.id = Number(id);
    data.attachment_url = data.attachmenturl;
    console.log("update",data);
    
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    UpdateRecord(updaterecord, data).then((res) => {
      if (res.success) {
        store.stopLoading();
        SuccessAlert("Project Updated Successfully");
        store.navback();
        store.reset(init);
      }
      store.stopLoading();
    });
  }
 function Submit() {
    store.startLoading();
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.createdBy = store.getuser().id;
    console.log({setrecord,data});

    if (CheckMandatory(tablename, store)) {
      saveRecord(setrecord, data).then((res) => {
        console.log("Resonce of saveRecord - ", res);
        if (res.success) {
          store.stopLoading();
          SuccessAlert("Project Submitted Successfully");
          store.navTo(
            "/" + URLPRIFIX + "/trademark/projects/view/" + res.Id.projectDetails.id
          );
          store.reset(init);
        }
         store.stopLoading();
      });
    }
  }

  return (
    <FormView
      actions={[
        {
          Name: "Back",
          onclick: () => {
            // store.navTo("/" + URLPRIFIX + "/projects/list");
            store.navback();
            store.reset(init);
          },
          visible: true,
        },

        {
          Name: "Submit",
          onclick: Submit,
          type: "primary",
          visible: id === undefined,
        },
        {
          Name: "Update",
          onclick: update,
          type: "primary",
          visible: option === "edit" && store.getprops("update"),
        },
        {
          Name: "Create Task",
          type: "primary",
          onclick: () => {
            let data = store.getrecord();
            console.log("autofill check On Create Project - ", data, id);

            store.setrecord("document", {
              project_id: id,
              customer_id: data.customer_id,
              assigned_to: "",
              task_title: "",
              dueDate: "",
              priority: "",
              editor_id: "",
              status: "Draft",
              description: "",
              attachmenturl: [],
              activeStatus: true,
              child: [],
            });
            store.navTo(
              "/demo/documents/create?project_id=readonly&contact_id=readonly"
            );
          },
          visible: option === "view" && getlocal("properties")?.document?.add,
        },
      ]}
    >
      <div className="grid grid-cols-2 max-md:grid-cols-1 gap-6">
        <String name="projectName" label="Project Name :" />

        <Dropdown
          name="contact_id"
          label="Customer Name :"
          list={store.getprops("clientname_list") || []}
          //  onchange={(event) => {
          //   let clientname_list = store.getprops("clientname_list") || [];
          //   clientname_list = clientname_list.filter(
          //     (item) => item.value == event
          //   )[0];
          //   store.setvalue("clientName", clientname_list?.label);
          // }}
        />
        <Dropdown
          name="priority"
          label="Priority :"
          list={[
            { value: "High", label: "High" },
            { value: "Medium", label: "Medium" },
            { value: "Low", label: "Low" },
          ]}
        />
                <div className="col-span-2 max-md:col-span-1">
          <Journal
            name="projectDescription"
            label="Project Description :"
            doubleline={true}
          />
        </div>
        <Dropdown
          name="status"
          label="Status :"
          list={[
            { value: "Draft", label: "Draft" },
            { value: "inprogress", label: "InProgress" },
            { value: "Completed", label: "Completed" },
            { value: "Billed", label: "Billed" },
          ]}
        />
        <Dropdown
          name="activeStatus"
          label="Active Status :"
          list={[
            { value: true, label: "Active" },
            { value: false, label: "InActive" },
          ]}
        />

        <DatePicker name="startDate" label="Start Date :" />
        <DatePicker name="endDate" label="End Date :" />
        <div className="col-span-2 max-md:col-span-1 px-2">
          <div className="font-bold text-[20px]">Attachements</div>
          <div className="grid grid-cols-5">
            {store.getvalue("attachmenturl")?.length > 0 ? (
              store.getvalue("attachmenturl").map((item, index) => {
                // console.log("test 223", item);
                return (
                  <div className="col-span-1 flex border-r pr-6 w-full  items-center">
                    <Attachement name={"attachmenturl"} fileurl={item} />
                    <button
                      className="hover:bg-tertiary p-1 h-fit w-fit rounded-full"
                      onClick={() => {
                        let arr = [];
                        store
                          .getvalue("attachmenturl")
                          .map((attachitem, attachindex) => {
                            if (attachindex !== index) {
                              arr.push(attachitem);
                            }
                          });
                        store.setvalue("attachmenturl", arr);
                      }}
                    >
                      X
                    </button>
                  </div>
                );
              })
            ) : (
              <>No Data</>
            )}
            {option !== "view" && (
              <FileInput
                prefix={"project" + id}
                onchange={(file) => {
                  let attachmenturl = store.getvalue("attachmenturl") || [];
                  attachmenturl.push(file);
                  store.setvalue("attachmenturl", attachmenturl);
                }}
              />
            )}
          </div>
        </div>

        <div className="col-span-2 max-md:col-span-1 px-2">
          {store.getvalue("child") &&
            store.getvalue("child").map((child) => (
              <>
                {" "}
                <div className="font-bold text-[20px]">
                  {child.table.toUpperCase()}
                </div>
                <ListView
                  Tablehead={
                    "min-w-[150px] pr-[10px] max-sm:text-[26px] md:text-[20px] lg:text-[16px] xl:text-[20px] font-bold"
                  }
                  Tablerecord="pr-[10px]  max-sm:text-[26px] md:text-[20px] lg:text-[13px] xl:text-[16px] cursor-pointer "
                  onview={(index) => {
                    store.navTo("/demo/documents/view/" + index);
                  }}
                  height={"fit"}
                  header={child.header}
                  records={child.list}
                />
              </>
            ))}
        </div>
      </div>
    </FormView>
  );
};

export default TProjectForm;
