import { UploadFile, WarningAlert } from "../../functions/api";
import { useStore } from "../StateProvider";

const FileInput = ({
  className = { field: "", lable: "", input: "" },
  name = "",
  label = "",
  doubleline = false,
  prefix = "",
  readonly = false,
  onchange = () => {},
}) => {
  const store = useStore();
  let props = store.getprops(name) || {};

  if (!props.hidden) {
    return (
      <div
        className={
          (className.field === ""
            ? "flex text-[8px]  md:text-[14px]  m:w-full m:h-full mt-2 gap-3 px-0 md:px-3"
            : className.field) +
          (doubleline ? "flex-col " : "flex-row items-center")
        }
      >
        {label != "" && (
          <label
            className={
              className.lable === ""
                ? "w-fit  min-w-[150px] max-w-full"
                : className.lable
            }
            onClick={() => {
              document.getElementById(name).focus();
            }}
          >
           {label}
          </label>
        )}
        <input
  className={
    className.input === "" ? "md:w-full w-[80px] rounded-lg text-secondary pl-1" : className.input
  }
  id={name}
  type="file"
  disabled={readonly}
  title=""
  onChange={(event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("Selected file:", file);
      console.log("File size:", file.size);  
      const MAX_FILE_SIZE = 10 * 1024 * 1024; 
      if (file.size > MAX_FILE_SIZE) {
        WarningAlert("File size exceeds 10 MB. Please select a smaller file.");
        return; 
      }
      var bodyFormData = new FormData();
      bodyFormData.append("file", file);

      UploadFile(prefix, bodyFormData).then((res) => {
        if (res.success) {
          name !== "" && store.setvalue(name, res.data);
          onchange(res.data);
        }
      });
    } 
  }}
/>


        {/* <input
          className={
            className.input === "" ? "md:w-full w-[80px] rounded-lg text-secondary pl-1" : className.input
          }
          id={name}
          type="file"
          disabled={readonly}
          title=""
          // value={store.getvalue(name+"_filename")?"C:\\fakelocation//"+store.getvalue(name+"_filename"):""}
          onChange={(event) => {
            console.log("FILE$#$#$INPUT",event);
            var bodyFormData = new FormData();
            bodyFormData.append("file", event.target.files[0]);
            UploadFile(prefix, bodyFormData).then((res) => {
              if (res.success) {
                name !== "" && store.setvalue(name, res.data);
                onchange(res.data);
              }
            });
            // store.setvalue(name+"_filename", event.target.files[0].name);
          }}
        /> */}
        {/* {store.getvalue(name)?.replace(prefix + "/", "")} */}
      </div>
    );
  }
};

export default FileInput;
